import { all, call, takeEvery, fork } from "redux-saga/effects";
import requestFunction from "../Utils/sagasGenericFunction";

import {SEND_NOTIFICATION } from "../ActionTypes";

import { postSendNotification } from "../Api";

import { sendNotificationSuccess } from "../Actions";

export const sendNotificationRequest = function* ({ payload }) {
  yield call(requestFunction, sendNotificationSuccess, postSendNotification, payload);
};

export const sendNotif = function* () {
  yield takeEvery(SEND_NOTIFICATION, sendNotificationRequest);
};


export default function* rootSaga() {
    yield all([
      
      fork(sendNotif),
     
    ]);
  }
  