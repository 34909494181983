import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Preloader from "./components/preloader/Preloader";
import store from "./Store";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
// import ProtectedRouteSuperAdmin from "./RouteGuard/ProtectedRouteSuperAdmin";
// import ProtectedRouteCustomerRep from "./RouteGuard/ProtectedRouteCustomerRep";
// import ProtectedRoutePublisher from "./RouteGuard/ProtectedRoutePublisher";






const LoginPage = lazy(() => import("./pages/Login"));
const ForgotPasswordPage = lazy(() => import("./pages/ForgotPassword"));
const ResetPasswordPage = lazy(() => import("./pages/ResetPassword"));
const HomePage = lazy(() => import("./pages/Home"));
const DashboardPage = lazy(() => import("./pages/Dashboard"));
const ReportPage = lazy(() => import("./pages/Report"));
const AuthorsPage = lazy(() => import("./pages/Authors"));
const GenrePage = lazy(() => import("./pages/Genres"));
const BooksPage = lazy(() => import("./pages/Books"));
const UsersPage = lazy(() => import("./pages/Users"));
const AllUsersPage = lazy(() => import("./pages/AllUsers"));
const ReferralInfoPage = lazy(() => import("./pages/ReferralInfo"));
const ReferralPage = lazy(() => import("./pages/Referral"));
const GroupPlanPage = lazy(() => import("./pages/GroupPlan"));
const PublisherPage = lazy(() => import("./pages/Publisher"));
const PublisherBookPage = lazy(() => import("./pages/PublisherBooks"));
const NotificationPage = lazy(() => import("./pages/Notifications"));
const RolesPage = lazy(() => import("./pages/Roles"));
const AllBooksPage = lazy(() => import("./pages/AllBooks"));
const BookReportPage = lazy(() => import("./pages/BookReport"));
const SubReport = lazy(() => import("./pages/SubscriptionReport"));
const UserReport = lazy(() => import("./pages/UserReport"));
const BookDetailsPage = lazy(() => import("./pages/BookDetails"));
const UserDetailsPage = lazy(() => import("./pages/UserDetails"))
const WithdrawalDetailsPage = lazy(() => import("./pages/WithdrawalDetails"))
const WithdrawalRequestsPage = lazy(() => import("./pages/WithdrawalRequests"))
const BlogPage  = lazy(() => import("./pages/Blog"));
const NotfoundPage = lazy(() => import("./pages/NotFoundPage"));

const SongsPage = lazy(() => import("./pages/Songs"));


const Rout = () => {

  const userDataLogin = JSON.parse(localStorage.getItem("userLoginData"));

  const user = userDataLogin !== null ? userDataLogin : null;


  return (
    <Provider store={store}>
    <BrowserRouter>
      <Suspense fallback={<Preloader />}>
        <Routes>
          <Route path="/" element={<LoginPage user={user} />} />
          <Route path="forgotPassword" element={<ForgotPasswordPage user={user} />} />
          <Route path="resetPassword" element={<ResetPasswordPage user={user} />} />

          <Route  element={<HomePage user={user} />}>
            <Route path="dashboard" element={<DashboardPage />} />
            <Route path="report" element={<ReportPage />}>
              <Route path="subscriptionReport" element={<SubReport />} />
            </Route>
            <Route path="books" element={<BooksPage />}>
              <Route path="allBooks" element={<AllBooksPage />} />
              <Route path="authors" element={<AuthorsPage />} />
              <Route path="genres" element={<GenrePage />} />
              <Route path="publisher" element={<PublisherPage />} />
              <Route path="bookDetails" element={<BookDetailsPage />} />
              <Route path="bookReport" element={<BookReportPage />} />
            </Route>
            <Route path="publisherBook/:name/:id" element={<PublisherBookPage />} />
            <Route path="users" element={<UsersPage />}>
              <Route path="allUsers" element={<AllUsersPage />} />
              <Route path="userDetails" element={<UserDetailsPage />} />
              <Route path="userReport" element={<UserReport />} />
            </Route>
            <Route path="referralandwithdrawals" element={<ReferralInfoPage />}>
                <Route path="referralInformation" element={<ReferralPage />} />
                <Route path="successfulWithdrawal" element={<WithdrawalDetailsPage />} />
                <Route path="withdrawalRequests" element={<WithdrawalRequestsPage />} />

                


            </Route>

            <Route path="plan" element={<GroupPlanPage />} />
            <Route path="notification" element={<NotificationPage />} />
            <Route path="roles" element={<RolesPage />} />
            <Route path="songs" element={<SongsPage />} />
            <Route path="blog" element={<BlogPage />} />


          </Route>

          <Route path="*" element={<NotfoundPage />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
    <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          getState={(state) => state.toastr}
          transitionIn="bounceIn"
          transitionOut="bounceOut"
          progressBar
          closeOnToastrClick
        />
    </Provider>

  );
};

export default Rout;
