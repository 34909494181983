import { all } from "redux-saga/effects";

import authSagas from "./auth";
import dashboardSagas from "./dashboard";
import reportsSagas from "./report";
import notificationSagas from "./notification";
import authorSagas from "./authors";
import genreSagas from "./genre";
import bookSagas from "./book";
import userSagas from "./user";
import publisherSagas from "./publisher";
import miscSagas from "./misc";
import referralSagas from "./referral";
import rolesSagas from "./roles";
import blogSagas from "./blog"

export default function* rootSaga(getState) {
  yield all([
    
    authSagas(), 
    dashboardSagas(),
    reportsSagas(),
    notificationSagas(),
    authorSagas(),
    genreSagas(),
    bookSagas(),
    userSagas(),
    publisherSagas(),
    miscSagas(),
    referralSagas(),
    rolesSagas(),
    blogSagas()
    

  
  ]);
}
