import api from "../Service";
import { toastr } from "react-redux-toastr";


const baseControllerAdmin = "api/admin/";
const baseControllerUser = "api/user/";



export const postlogin = async (body) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.post(`${baseControllerAdmin}login`, body);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            localStorage.clear();
            localStorage.setItem("userLoginData", JSON.stringify(response.data));
            return response.data;
          } else {
            toastr.error("User does not exist", "Please check your email and password");
            return response.data;
          }
        } else {
          toastr.error("Login", "Login Failed");
        }
      } catch (ex) {
        toastr.error("Login", "An error occured");
      }
    }
  };
  
  export const postForgotPassword = async (body) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.post(`${baseControllerUser}forgot`, body);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            toastr.success("Mail Sent Successfully", "Please check your mail");
            return response.data.status;
          } else {
            toastr.error("Couldnt Find email", "Please ensure this is the email you used to sign up");
            return response.data.status;
          }
        } else {
          toastr.error("Email", "An Error occured");
        }
      } catch (ex) {
        toastr.error("Email", "Please try again");
        //   return ex.response.data.message
      }
    }
  };
  
  export const postResetPassword = async (body) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.post(`${baseControllerUser}reset`, body);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            toastr.success("Reset Password Successful", "");
            return response.data.status;
          } else {
            toastr.error(
              "Couldnt reset password",
              "Please ensure you inputed the correct reset token"
            );
            return response.data.status;
          }
        } else {
          toastr.error("Reset Password", "An Error occured");
        }
      } catch (ex) {
        toastr.error("Reset Password", "Please try again");
        //   return ex.response.data.message
      }
    }
  };
  
