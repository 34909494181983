import { all, call, takeEvery, fork } from "redux-saga/effects";
import requestFunction from "../Utils/sagasGenericFunction";

import { FETCH_ALL_BLOGS, FETCH_BLOG_BY_ID, CREATE_BLOG, UPDATE_BLOG, DELETE_BLOG, ADD_BLOG_URL} from "../ActionTypes";

import { getBlogs, getBlogById, addBlog, editBlog, createBlogUrl, deleteBlog } from "../Api";

import { fetchAllBlogsSuccess, fetchBlogByIdSuccess, addBlogSuccess, editBlogSuccess, addBlogUrlSuccess, deleteBlogSuccess } from "../Actions";

export const fetchBlogRequest = function* ({ payload }) {
  yield call(requestFunction, fetchAllBlogsSuccess, getBlogs, payload);
};

export const fetchBlgs = function* () {
  yield takeEvery(FETCH_ALL_BLOGS, fetchBlogRequest);
};

export const fetchBlogByIdRequest = function* ({ payload }) {
  yield call(requestFunction, fetchBlogByIdSuccess, getBlogById, payload);
};

export const fetchBlgById = function* () {
  yield takeEvery(FETCH_BLOG_BY_ID, fetchBlogByIdRequest);
};


export const addBlogRequest = function* ({ payload }) {
    yield call(requestFunction, addBlogSuccess, addBlog, payload);
  };
  
  export const addBlg = function* () {
    yield takeEvery(CREATE_BLOG, addBlogRequest);
  };
  


  export const editBlogRequest = function* ({ payload }) {
    yield call(requestFunction, editBlogSuccess, editBlog, payload);
  };
  
  export const editBlg = function* () {
    yield takeEvery(UPDATE_BLOG, editBlogRequest);
  };
  

  export const addBlogUrlRequest = function* ({ payload }) {
    yield call(requestFunction, addBlogUrlSuccess, createBlogUrl, payload);
  };
  
  export const addBlgUrl = function* () {
    yield takeEvery(ADD_BLOG_URL, addBlogUrlRequest);
  };
  

  export const deleteBlogRequest = function* ({ payload }) {
    yield call(requestFunction, deleteBlogSuccess, deleteBlog, payload);
  };
  
  export const deleteBlg = function* () {
    yield takeEvery(DELETE_BLOG, deleteBlogRequest);
  };
  


export default function* rootSaga() {
  yield all([
    
    fork(fetchBlgs), 
    fork(fetchBlgById),
    fork(addBlg),
    fork(editBlg),
    fork(addBlgUrl),
    fork(deleteBlg)


]);
}
