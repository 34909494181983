import api from "../Service";
import { toastr } from "react-redux-toastr";

const baseController = "api/admin/";
const baseControllerServices = "api/services/";


export const getUsers = async (id) => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard/user/all?page=${id}`);
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          return response.data.data;
        }
      } else {
        toastr.error("An Error occured", "Could not retrieve users");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};






export const cancelSub = async (id) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.get(`${baseController}dashboard/user/subscription/cancel/${id}`);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            toastr.success("User Subscription Cancelled", "");
            return response.data.status;
          }
        } else {
          toastr.error("An Error occured", "Could not cancel subscription");
        }
      } catch (ex) {
        toastr.error("An Error occurred", "Please try again");
      }
    }
  };



  export const reactivateSub = async (id) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.get(`${baseController}dashboard/user/subscription/reactivate/${id}`);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            toastr.success("User Subscription Reactivated", "");
            return response.data.status;
          }
        } else {
          toastr.error("An Error occured", "Could not reactivate subscription");
        }
      } catch (ex) {
        toastr.error("An Error occurred", "Please try again");
      }
    }
  };


  export const trialSub = async (id) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.get(`${baseController}dashboard/user/subscription/trial/${id}`);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            toastr.success("Plan Activated", "");
            return response.data.status;
          }
        } else {
          toastr.error("An Error occured", "Could not activate plan");
        }
      } catch (ex) {
        toastr.error("An Error occurred", "Please try again");
      }
    }
  };


  export const loneRangerMonthlySub = async (id) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.get(`${baseController}dashboard/user/subscription/lone-ranger-monthly/${id}`);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            toastr.success("Plan Activated", "");
            return response.data.status;
          }
        } else {
          toastr.error("An Error occured", "Could not activate plan");
        }
      } catch (ex) {
        toastr.error("An Error occurred", "Please try again");
      }
    }
  };


  export const loneRangerQuaterlySub = async (id) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.get(`${baseController}dashboard/user/subscription/lone-ranger-quarterly/${id}`);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            toastr.success("Plan Activated", "");
            return response.data.status;
          }
        } else {
          toastr.error("An Error occured", "Could not activate plan");
        }
      } catch (ex) {
        toastr.error("An Error occurred", "Please try again");
      }
    }
  };



  export const loneRangerBiannualSub = async (id) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.get(`${baseController}dashboard/user/subscription/lone-ranger-biannually/${id}`);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            toastr.success("Plan Activated", "");
            return response.data.status;
          }
        } else {
          toastr.error("An Error occured", "Could not activate plan");
        }
      } catch (ex) {
        toastr.error("An Error occurred", "Please try again");
      }
    }
  };


  export const loneRangerYearlySub = async (id) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.get(`${baseController}dashboard/user/subscription/lone-ranger-yearly/${id}`);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            toastr.success("Plan Activated", "");
            return response.data.status;
          }
        } else {
          toastr.error("An Error occured", "Could not activate plan");
        }
      } catch (ex) {
        toastr.error("An Error occurred", "Please try again");
      }
    }
  };


  export const extendUserSub = async (body) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.post(`${baseController}dashboard/user/subscription/extend/${body.id}`, body);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            toastr.success(`${response.data.message}`, "Success");
            return response.data.status;
          } else {
            toastr.error("Something went wrong", "Please try again");
            return response.data.status;
          }
        } else {
          toastr.error("An Error Occured", "Please try again");
        }
      } catch (ex) {
        toastr.error("An Error Occured", "Please try again");
            return ex.response.data.message
      }
    }
  };




  export const searchUsers = async (body) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
        try {
            const response = await api.post(`${baseController}dashboard/user/search`, body);
            if (typeof response !== "undefined") {
              if (response.status === 200 && response.data.status === true) {
                return response.data.data;
              }
              else{
                toastr.error("User not found", "Please check the email used");
              }
            } else {
              toastr.error("An Error occured", "Could not retrieve user");
            }
          } catch (ex) {
            toastr.error("An Error occurred", "Please try again");
          }
    }
  };



  export const deleteAUser = async (email) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.get(`${baseControllerServices}delete/${email}`);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            toastr.success(`${response.data.message}`, "Success");
            return response.data.status;
          } else {
            toastr.error("Something went wrong", "Please try again");
            return response.data.status;
          }
        } else {
          toastr.error("An Error Occured", "Please try again");
        }
      } catch (ex) {
        toastr.error("An Error Occured", "Please try again");
            return ex.response.data.message
      }
    }
  };



  export const updateUserDetails = async (body) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.post(`${baseController}dashboard/user/profile/edit/${body.id}`, body);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            toastr.success(`User ${response.data.message}`, "Success");
            return response.data.status;
          } else {
            toastr.error("Something went wrong", "Please try again");
            return response.data.status;
          }
        } else {
          toastr.error("An Error Occured", "Please try again");
        }
      } catch (ex) {
        toastr.error("An Error Occured", "Please try again");
            return ex.response.data.message
      }
    }
  };



  export const getUserByUserId = async (id) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
        try {
            const response = await api.get(`${baseController}dashboard/user/get/${id}`);
            if (typeof response !== "undefined") {
              if (response.status === 200 && response.data.status === true) {
                return response.data.data;
              }
              else{
                toastr.error("User details not found", "");
              }
            } else {
              toastr.error("An Error occured", "Could not retrieve user");
            }
          } catch (ex) {
            toastr.error("An Error occurred", "Please try again");
          }
    }
  };