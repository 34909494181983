import { 
  
  
   FETCH_SUBSCRIPTION_REPORT,
   FETCH_SUBSCRIPTION_REPORT_SUCCESS,
   FETCH_BOOK_REPORT,
   FETCH_BOOK_REPORT_SUCCESS,
   FETCH_USERS_REPORT,
   FETCH_USERS_REPORT_SUCCESS,

   DOWNLOAD_BOOK_REPORT,
   DOWNLOAD_BOOK_REPORT_SUCCESS,
   RESTORE_DOWNLOAD_BOOK_REPORT_INITIAL,

   DOWNLOAD_SUB_REPORT,
   DOWNLOAD_SUB_REPORT_SUCCESS,
   RESTORE_DOWNLOAD_SUB_REPORT_INITIAL,

   DOWNLOAD_ALL_USERS_REPORT,
   DOWNLOAD_ALL_USERS_REPORT_SUCCESS,
   RESTORE_DOWNLOAD_ALL_USERS_REPORT_INITIAL,

   DOWNLOAD_TRIAL_USERS_REPORT,
   DOWNLOAD_TRIAL_USERS_REPORT_SUCCESS,
   RESTORE_DOWNLOAD_TRIAL_USERS_REPORT_INITIAL,

   DOWNLOAD_EXPIRED_USERS_REPORT,
   DOWNLOAD_EXPIRED_USERS_REPORT_SUCCESS,
   RESTORE_DOWNLOAD_EXPIRED_USERS_REPORT_INITIAL,

   DOWNLOAD_NEVERSUBSCRIBED_USERS_REPORT,
   DOWNLOAD_NEVERSUBSCRIBED_USERS_REPORT_SUCCESS,
   RESTORE_DOWNLOAD_NEVERSUBSCRIBED_USERS_REPORT_INITIAL,

   DOWNLOAD_LONERANGER_USERS_REPORT,
   DOWNLOAD_LONERANGER_USERS_REPORT_SUCCESS,
   RESTORE_DOWNLOAD_LONERANGER_USERS_REPORT_INITIAL,

   DOWNLOAD_UNVERIFIED_USERS_REPORT,
   DOWNLOAD_UNVERIFIED_USERS_REPORT_SUCCESS,
   RESTORE_DOWNLOAD_UNVERIFIED_USERS_REPORT_INITIAL,

   FETCH_TRANSACTION_REPORT,
   FETCH_TRANSACTION_REPORT_SUCCESS,


   DOWNLOAD_TRANSACTION_REPORT_CSV,
   DOWNLOAD_TRANSACTION_REPORT_CSV_SUCCESS,
   RESTORE_DOWNLOAD_TRANSACTION_REPORT_CSV_INITIAL,


    FETCH_ALL_SUBSCRIPTIONS,
    FETCH_ALL_SUBSCRIPTIONS_SUCCESS,
    FILTER_USERS,
    FILTER_USER_SUCCESS
  
  } from "../ActionTypes";



  export const fetchBookReport = (id) => ({
    type: FETCH_BOOK_REPORT,
    payload:id
  });
  
  export const fetchBookReportSuccess = (payload) => ({
    type: FETCH_BOOK_REPORT_SUCCESS,
    payload,
  });



  export const downloadBookReport = () => ({
    type: DOWNLOAD_BOOK_REPORT,
  });
  
  export const downloadBookReportSuccess = (payload) => ({
    type: DOWNLOAD_BOOK_REPORT_SUCCESS,
    payload,
  });

  export const restoreDownloadBookReportInitial = () => ({
    type: RESTORE_DOWNLOAD_BOOK_REPORT_INITIAL,
  });



  export const downloadSubReport = () => ({
    type: DOWNLOAD_SUB_REPORT,
  });
  
  export const downloadSubReportSuccess = (payload) => ({
    type: DOWNLOAD_SUB_REPORT_SUCCESS,
    payload,
  });

  export const restoreDownloadSubReportInitial = () => ({
    type: RESTORE_DOWNLOAD_SUB_REPORT_INITIAL,
  });


  export const fetchUsersReport = (id) => ({
    type: FETCH_USERS_REPORT,
    payload:id
  });
  
  export const fetchUsersReportSuccess = (payload) => ({
    type: FETCH_USERS_REPORT_SUCCESS,
    payload,
  });
  


  export const fetchSubscriptionReport = (id) => ({
    type: FETCH_SUBSCRIPTION_REPORT,
    payload: id
  });
  
  export const fetchSubscriptionReportSuccess = (payload) => ({
    type: FETCH_SUBSCRIPTION_REPORT_SUCCESS,
    payload,
  });



  export const downloadAllUsersReport = () => ({
    type: DOWNLOAD_ALL_USERS_REPORT,
  });
  
  export const downloadAllUsersReportSuccess = (payload) => ({
    type: DOWNLOAD_ALL_USERS_REPORT_SUCCESS,
    payload,
  });

  export const restoreDownloadAllUsersReportInitial = () => ({
    type: RESTORE_DOWNLOAD_ALL_USERS_REPORT_INITIAL,
  });



  export const downloadTrialUsersReport = () => ({
    type: DOWNLOAD_TRIAL_USERS_REPORT,
  });
  
  export const downloadTrialUsersReportSuccess = (payload) => ({
    type: DOWNLOAD_TRIAL_USERS_REPORT_SUCCESS,
    payload,
  });

  export const restoreDownloadTrialUsersReportInitial = () => ({
    type: RESTORE_DOWNLOAD_TRIAL_USERS_REPORT_INITIAL,
  });



  export const downloadExpiredUsersReport = () => ({
    type: DOWNLOAD_EXPIRED_USERS_REPORT,
  });
  
  export const downloadExpiredUsersReportSuccess = (payload) => ({
    type: DOWNLOAD_EXPIRED_USERS_REPORT_SUCCESS,
    payload,
  });

  export const restoreDownloadExpiredUsersReportInitial = () => ({
    type: RESTORE_DOWNLOAD_EXPIRED_USERS_REPORT_INITIAL,
  });



  export const downloadNeverSubscribedUsersReport = () => ({
    type: DOWNLOAD_NEVERSUBSCRIBED_USERS_REPORT,
  });
  
  export const downloadNeverSubscribedUsersReportSuccess = (payload) => ({
    type: DOWNLOAD_NEVERSUBSCRIBED_USERS_REPORT_SUCCESS,
    payload,
  });

  export const restoreDownloadNeverSubscribedUsersReportInitial = () => ({
    type: RESTORE_DOWNLOAD_NEVERSUBSCRIBED_USERS_REPORT_INITIAL,
  });



  export const downloadLonerangerUsersReport = () => ({
    type: DOWNLOAD_LONERANGER_USERS_REPORT,
  });
  
  export const downloadLonerangerUsersReportSuccess = (payload) => ({
    type: DOWNLOAD_LONERANGER_USERS_REPORT_SUCCESS,
    payload,
  });

  export const restoreDownloadLonerangerUsersReportInitial = () => ({
    type: RESTORE_DOWNLOAD_LONERANGER_USERS_REPORT_INITIAL,
  });



  export const downloadUnverifiedUsersReport = () => ({
    type: DOWNLOAD_UNVERIFIED_USERS_REPORT,
  });
  
  export const downloadUnverifiedUsersReportSuccess = (payload) => ({
    type: DOWNLOAD_UNVERIFIED_USERS_REPORT_SUCCESS,
    payload,
  });

  export const restoreDownloadUnverifiedUsersReportInitial = () => ({
    type: RESTORE_DOWNLOAD_UNVERIFIED_USERS_REPORT_INITIAL,
  });


  export const fetchTransactionReport = () => ({
    type: FETCH_TRANSACTION_REPORT,
  });
  
  export const fetchTransactionReportSuccess = (payload) => ({
    type: FETCH_TRANSACTION_REPORT_SUCCESS,
    payload,
  });


  export const downloadTransactionReport = () => ({
    type: DOWNLOAD_TRANSACTION_REPORT_CSV,
  });
  
  export const downloadTransactionReportSuccess = (payload) => ({
    type: DOWNLOAD_TRANSACTION_REPORT_CSV_SUCCESS,
    payload,
  });

  export const restoreDownloadTransactionReportInitial = () => ({
    type: RESTORE_DOWNLOAD_TRANSACTION_REPORT_CSV_INITIAL,
  });



  export const fetchAllSubscription = () => ({
    type: FETCH_ALL_SUBSCRIPTIONS,
  });
  
  export const fetchAllSubscriptionSuccess = (payload) => ({
    type: FETCH_ALL_SUBSCRIPTIONS_SUCCESS,
    payload,
  });



  export const filterUsers = ({plan, status}) => ({
    type: FILTER_USERS,
    payload:{
      plan,
      status
    }
  });
  
  export const filterUsersSuccess = (payload) => ({
    type: FILTER_USER_SUCCESS,
    payload,
  });