
import api from "../Service";
import { toastr } from "react-redux-toastr";

const baseController = "api/admin/";



export const getBooks = async (id) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.get(`${baseController}dashboard/book/all?page=${id}`);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            return response.data.data;
          }
        } else {
          toastr.error("An Error occured", "Could not retrieve books");
        }
      } catch (ex) {
        toastr.error("An Error occurred", "Please try again");
      }
    }
  };
  



export const addBooks = async (body) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.post(`${baseController}dashboard/book/add`, body, {
            headers: {
                'content-type': 'multipart/form-data' 
          }
        });
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            toastr.success(`${response.data.message}`, "Success");
            return response.data.status;
          } else {
            toastr.error("Something went wrong", "Please try again");
            return response.data.status;
          }
        } else {
          toastr.error("An Error Occured", "Please try again");
        }
      } catch (ex) {
        toastr.error("An Error Occured", "Please try again");
            return ex.response.data.message
      }
    }
  };
  


  export const editBooks = async (body) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.post(`${baseController}dashboard/book/edit/${body.bookId}`, body.formData, {
            headers: {
                'content-type': 'multipart/form-data' 
          }
        });
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            toastr.success(`${response.data.message}`, "Success");
            return response.data.status;
          } else {
            toastr.error("Something went wrong", "Please try again");
            return response.data.status;
          }
        } else {
          toastr.error("An Error Occured", "Please try again");
        }
      } catch (ex) {
        toastr.error("An Error Occured", "Please try again");
            return ex.response.data.message
      }
    }
  };



  export const deleteBook = async (id) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.get(`${baseController}dashboard/book/delete/${id}`);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            toastr.success("Book Deleted Successfully", "");
            return response.data.status;
          }
        } else {
          toastr.error("An Error occured", "Could not delete Book");
        }
      } catch (ex) {
        toastr.error("An Error occurred", "Please try again");
      }
    }
  };


  export const getBooksById = async (id) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.get(`${baseController}dashboard/book/${id}`);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            return response.data.data;
          }
        } else {
          toastr.error("An Error occured", "Could not retrieve book details");
        }
      } catch (ex) {
        toastr.error("An Error occurred", "Please try again");
      }
    }
  };




  export const searchBooks = async (body) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
        try {
            const response = await api.post(`${baseController}dashboard/book/search`, body);
            if (typeof response !== "undefined") {
              if (response.status === 200 && response.data.status === true) {
                if(response.data.data.length === 0){
                  toastr.warning('Opps', 'Book not found Please try another search term')
                }
                return response.data.data;
              }
              else{
                toastr.error("User not found", "Please check the name used");
              }
            } else {
              toastr.error("An Error occured", "Could not retrieve book");
            }
          } catch (ex) {
            toastr.error("An Error occurred", "Please try again");
          }
    }
  };