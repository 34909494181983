import { all, call, takeEvery, fork } from "redux-saga/effects";
import requestFunction from "../Utils/sagasGenericFunction";

import { FETCH_ALL_ADMIN_USERS, CREATE_ADMIN_USERS, EDIT_ADMIN_USERS, DELETE_ADMIN_USERS, FETCH_ADMIN_USERS_BY_ID } from "../ActionTypes";

import { getAdminUsers, addAdminUsr, updateAdminUserDetails, deleteAdminUser, getAdminUserById } from "../Api";

import { fetchAllAdminUserSuccess, addAdminUserSuccess, editAdminUserSuccess, deleteAdminUsersSuccess, fetchAdminUserByIdSuccess } from "../Actions";

export const fetchAdminUserRequest = function* ({ payload }) {
  yield call(requestFunction, fetchAllAdminUserSuccess, getAdminUsers, payload);
};

export const fetchAdmnUsrr = function* () {
  yield takeEvery(FETCH_ALL_ADMIN_USERS, fetchAdminUserRequest);
};



export const createAdminUserRequest = function* ({ payload }) {
    yield call(requestFunction, addAdminUserSuccess, addAdminUsr, payload);
  };
  
  export const createAdmnUsrr = function* () {
    yield takeEvery(CREATE_ADMIN_USERS, createAdminUserRequest);
  };
  

  export const updateAdminUserRequest = function* ({ payload }) {
    yield call(requestFunction, editAdminUserSuccess, updateAdminUserDetails, payload);
  };
  
  export const updateAdmnUsrr = function* () {
    yield takeEvery(EDIT_ADMIN_USERS, updateAdminUserRequest);
  };


  export const deleteAdminUserRequest = function* ({ payload }) {
    yield call(requestFunction, deleteAdminUsersSuccess, deleteAdminUser, payload);
  };
  
  export const deleteAdmnUsrr = function* () {
    yield takeEvery(DELETE_ADMIN_USERS, deleteAdminUserRequest);
  };
  
  
  export const fetchAdminUserByIdRequest = function* ({ payload }) {
    yield call(requestFunction, fetchAdminUserByIdSuccess, getAdminUserById, payload);
  };
  
  export const fetchAdmnUsrrbyId = function* () {
    yield takeEvery(FETCH_ADMIN_USERS_BY_ID, fetchAdminUserByIdRequest);
  };


export default function* rootSaga() {
    yield all([
      
      fork(fetchAdmnUsrr),
    fork(createAdmnUsrr),
    fork(updateAdmnUsrr),
    fork(deleteAdmnUsrr),
    fork(fetchAdmnUsrrbyId),
    
    ]);
  }
  