import { 
  
  
    FETCH_ALL_ADMIN_USERS,
    FETCH_ALL_ADMIN_USERS_SUCCESS,
    CREATE_ADMIN_USERS,
    CREATE_ADMIN_USERS_SUCCESS,
    RESTORE_CREATE_ADMIN_USERS_INITIAL,
    EDIT_ADMIN_USERS,
    EDIT_ADMIN_USERS_SUCCESS,
    RESTORE_EDIT_ADMIN_USERS_INITIAL,
    DELETE_ADMIN_USERS,
    DELETE_ADMIN_USERS_SUCCESS,
    RESTORE_DELETE_ADMIN_USERS_INITIAL,
    FETCH_ADMIN_USERS_BY_ID,
    FETCH_ADMIN_USERS_BY_ID_SUCCESS
} from "../ActionTypes";
  
export const fetchAllAdminUser = () => ({
  type: FETCH_ALL_ADMIN_USERS,
});

export const fetchAllAdminUserSuccess = (payload) => ({
  type: FETCH_ALL_ADMIN_USERS_SUCCESS,
  payload,
});


export const addAdminUser = ({first_name, last_name, email, password, role, publisher_id}) => ({
    type: CREATE_ADMIN_USERS,
    payload:{
        first_name, last_name, email, password, role, publisher_id
    }
  });
  
  export const addAdminUserSuccess = (payload) => ({
    type: CREATE_ADMIN_USERS_SUCCESS,
    payload,
  });

  export const restoreCreateAdminUserInitial = () => ({
    type: RESTORE_CREATE_ADMIN_USERS_INITIAL,
  });


  export const editAdminUser = ({first_name, last_name, role, id}) => ({
    type: EDIT_ADMIN_USERS,
    payload:{
        first_name, last_name,role, id
    }
  });
  
  export const editAdminUserSuccess = (payload) => ({
    type: EDIT_ADMIN_USERS_SUCCESS,
    payload,
  });

  export const restoreEditAdminUserInitial = () => ({
    type: RESTORE_EDIT_ADMIN_USERS_INITIAL,
  });


  export const fetchAdminUserById = (id) => ({
    type: FETCH_ADMIN_USERS_BY_ID,
    payload: id
  });
  
  export const fetchAdminUserByIdSuccess = (payload) => ({
    type: FETCH_ADMIN_USERS_BY_ID_SUCCESS,
    payload,
  });


  export const deleteAdminUsers = (id) => ({
    type: DELETE_ADMIN_USERS,
    payload: id
  });
  
  export const deleteAdminUsersSuccess = (payload) => ({
    type: DELETE_ADMIN_USERS_SUCCESS,
    payload,
  });


  export const restoreDeleteAdminUserInitial = () => ({
    type: RESTORE_DELETE_ADMIN_USERS_INITIAL,
  });
  


  