import { all, call, takeEvery, fork } from "redux-saga/effects";
import requestFunction from "../Utils/sagasGenericFunction";

import { 
  
  FETCH_ALL_PUBLISHERS,
  FETCH_PUBLISHERS_BY_ID,
  FETCH_BOOKS_BY_PUBLISHER_ID,
  CREATE_PUBLISHER,
  EDIT_PUBLISHER,
  SEARCH_PUBLISHERS_BOOK,
  DELETE_PUBLISHER,

  FETCH_PUBLISHER_BOOKS,
  FETCH_PUBLISHER_BOOKS_PERFORMANCE


} from "../ActionTypes";

import { getPublishers, getPublishersById, getBooksByPublishersId, addPublisher, editPublisher, searchPublisherBks, deletePublisherr, getPublisherBooks, getPublisherBooksPerformance } from "../Api";

import { fetchPublishersSuccess, createPublisherSuccess, editPublisherSuccess, fetchPublisherByIdSuccess, fetchBooksByPublisherIdSuccess, searchPublisherBooksSuccess, deletePublisherSuccess, fetchPublishersBooksSuccess, fetchPublishersBooksPerformanceSuccess } from "../Actions";

export const fetchPublisherRequest = function* ({ payload }) {
  yield call(requestFunction, fetchPublishersSuccess, getPublishers, payload);
};

export const fetchAllPub = function* () {
  yield takeEvery(FETCH_ALL_PUBLISHERS, fetchPublisherRequest);
};


export const fetchPublisherByIdRequest = function* ({ payload }) {
  yield call(requestFunction, fetchPublisherByIdSuccess, getPublishersById, payload);
};

export const fetchPubById = function* () {
  yield takeEvery(FETCH_PUBLISHERS_BY_ID, fetchPublisherByIdRequest);
};


export const fetchBookByPublisherIdRequest = function* ({ payload }) {
  yield call(requestFunction, fetchBooksByPublisherIdSuccess, getBooksByPublishersId, payload);
};

export const fetchBookByPubId = function* () {
  yield takeEvery(FETCH_BOOKS_BY_PUBLISHER_ID, fetchBookByPublisherIdRequest);
};


export const addPublisherRequest = function* ({ payload }) {
  yield call(requestFunction, createPublisherSuccess, addPublisher, payload);
};

export const addPub = function* () {
  yield takeEvery(CREATE_PUBLISHER, addPublisherRequest);
};


export const editPublisherRequest = function* ({ payload }) {
  yield call(requestFunction, editPublisherSuccess, editPublisher, payload);
};

export const editPub = function* () {
  yield takeEvery(EDIT_PUBLISHER, editPublisherRequest);
};


export const searchPubBookRequest = function* ({ payload }) {
  yield call(requestFunction, searchPublisherBooksSuccess, searchPublisherBks, payload);
};

export const searchPubBkks = function* () {
  yield takeEvery(SEARCH_PUBLISHERS_BOOK, searchPubBookRequest);
};



export const deletePublisherRequest = function* ({ payload }) {
  yield call(requestFunction, deletePublisherSuccess, deletePublisherr, payload);
};

export const deletePub = function* () {
  yield takeEvery(DELETE_PUBLISHER, deletePublisherRequest);
};


export const fetchPublisherBooksRequest = function* ({ payload }) {
  yield call(requestFunction, fetchPublishersBooksSuccess, getPublisherBooks, payload);
};

export const fetchPubBooks = function* () {
  yield takeEvery(FETCH_PUBLISHER_BOOKS, fetchPublisherBooksRequest);
};


export const fetchPublisherBooksPerfRequest = function* ({ payload }) {
  yield call(requestFunction, fetchPublishersBooksPerformanceSuccess, getPublisherBooksPerformance, payload);
};

export const fetchPubBooksPerf = function* () {
  yield takeEvery(FETCH_PUBLISHER_BOOKS_PERFORMANCE, fetchPublisherBooksPerfRequest);
};




export default function* rootSaga() {
    yield all([
      
      fork(fetchAllPub),
      fork(fetchPubById),
      fork(fetchBookByPubId),
      fork(addPub),
      fork(editPub),
      fork(searchPubBkks),
      fork(deletePub),
      fork(fetchPubBooks),
      fork(fetchPubBooksPerf)
     
    ]);
  }
  