import {

    LOGIN,
    LOGIN_SUCCESS,
    RESTORE_LOGIN_INITIAL,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    RESTORE_FORGOT_PASSWORD_INITIAL,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESTORE_RESET_PASSWORD_INITIAL,
  

} from "../ActionTypes"


export const userLogin = ({ email, password }) => ({
    type: LOGIN,
    payload: {
      email,
      password,
    },
  });
  
  export const userLoginSuccess = (payload) => ({
    type: LOGIN_SUCCESS,
    payload,
  });
  
  export const restoreUserLoginInitial = () => ({
    type: RESTORE_LOGIN_INITIAL,
  });
  
  export const forgotPassword = ({ email }) => ({
    type: FORGOT_PASSWORD,
    payload: {
      email,
    },
  });
  
  export const forgotPasswordSuccess = (payload) => ({
    type: FORGOT_PASSWORD_SUCCESS,
    payload,
  });
  
  export const restoreForgotPasswordInitial = () => ({
    type: RESTORE_FORGOT_PASSWORD_INITIAL,
  });
  
  export const resetPassword = ({ token, password }) => ({
    type: RESET_PASSWORD,
    payload: {
      token,
      password,
    },
  });
  
  export const resetPasswordSuccess = (payload) => ({
    type: RESET_PASSWORD_SUCCESS,
    payload,
  });
  
  export const restoreResetPasswordInitial = () => ({
    type: RESTORE_RESET_PASSWORD_INITIAL,
  });