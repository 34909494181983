import { 
    

    FETCH_ALL_ADMIN_USERS_SUCCESS,
    CREATE_ADMIN_USERS_SUCCESS,
    RESTORE_CREATE_ADMIN_USERS_INITIAL,
    DELETE_ADMIN_USERS_SUCCESS,
    RESTORE_DELETE_ADMIN_USERS_INITIAL,
    EDIT_ADMIN_USERS_SUCCESS,
    RESTORE_EDIT_ADMIN_USERS_INITIAL,
    FETCH_ADMIN_USERS_BY_ID_SUCCESS

  
  } from "../ActionTypes";
  
  const INIT_STATE = {
    allAdminUsers: [],
    addAdminUserSuccess:false,
    addAdminUserFailure:false,
    adminUserById:{},
    editAdminUserSuccess:false,
    editAdminUserFailure:false,
    deleteAdminUserSuccess:false,
    deleteAdminUserFailure:false,

  }


  const rolesReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case FETCH_ALL_ADMIN_USERS_SUCCESS: {
        return {
          ...state,
          allAdminUsers: action.payload,
        };
      }  

      case CREATE_ADMIN_USERS_SUCCESS: {
        if (action.payload === true) {
          return {
            ...state,
            addAdminUserSuccess: true,
            addAdminUserFailure: false,
          };
        } else {
          return {
            ...state,
            addAdminUserSuccess: false,
            addAdminUserFailure: true,
          };
        }
      }
  
      case RESTORE_CREATE_ADMIN_USERS_INITIAL: {
        return {
          ...state,
          addAdminUserSuccess: false,
          addAdminUserFailure: false,
        };
      }

      case EDIT_ADMIN_USERS_SUCCESS: {
        if (action.payload === true) {
          return {
            ...state,
            editAdminUserSuccess: true,
            editAdminUserFailure: false,
          };
        } else {
          return {
            ...state,
            editAdminUserSuccess: false,
            editAdminUserFailure: true,
          };
        }
      }
  
      case RESTORE_EDIT_ADMIN_USERS_INITIAL: {
        return {
          ...state,
          editAdminUserSuccess: false,
          editAdminUserFailure: false,
        };
      }

      case DELETE_ADMIN_USERS_SUCCESS: {
        if (action.payload === true) {
          return {
            ...state,
            deleteAdminUserSuccess: true,
            deleteAdminUserFailure: false,
          };
        } else {
          return {
            ...state,
            deleteAdminUserSuccess: false,
            deleteAdminUserFailure: true,
          };
        }
      }
  
      case RESTORE_DELETE_ADMIN_USERS_INITIAL: {
        return {
          ...state,
          deleteAdminUserSuccess: false,
          deleteAdminUserFailure: false,
        };
      }


      case FETCH_ADMIN_USERS_BY_ID_SUCCESS: {
        return {
          ...state,
          adminUserById: action.payload,
        };
      }  
      
      
      default:
        return state;
    }
  };
  
  export default rolesReducer;