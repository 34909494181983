import { all, call, takeEvery, fork } from "redux-saga/effects";
import requestFunction from "../Utils/sagasGenericFunction";

import { FETCH_BOOK_REPORT, FETCH_USERS_REPORT, FETCH_SUBSCRIPTION_REPORT, DOWNLOAD_BOOK_REPORT, DOWNLOAD_SUB_REPORT, DOWNLOAD_ALL_USERS_REPORT, DOWNLOAD_TRIAL_USERS_REPORT, DOWNLOAD_EXPIRED_USERS_REPORT, DOWNLOAD_NEVERSUBSCRIBED_USERS_REPORT, DOWNLOAD_LONERANGER_USERS_REPORT, DOWNLOAD_UNVERIFIED_USERS_REPORT, FETCH_TRANSACTION_REPORT, DOWNLOAD_TRANSACTION_REPORT_CSV, FILTER_USERS, FETCH_ALL_SUBSCRIPTIONS  } from "../ActionTypes";

import { getBookReport, getUsersReport, getSubscriptionReport, downloadBookReportCSV, downloadSubReportCSV, downloadAllUsersReportCSV, downloadTrialUsersReportCSV, downloadExpiredUsersReportCSV, downloadNeverSubscribedUsersReportCSV, downloadLoneRangerUsersReportCSV, downloadUnverifiedUsersReportCSV, getTransactionReport, getTransactionReportCSV, getAllSubscription, filterUserbyPlanOrStatus  } from "../Api";

import { fetchBookReportSuccess, fetchSubscriptionReportSuccess, fetchUsersReportSuccess, downloadBookReportSuccess, downloadSubReportSuccess, downloadAllUsersReportSuccess, downloadTrialUsersReportSuccess, downloadExpiredUsersReportSuccess, downloadNeverSubscribedUsersReportSuccess, downloadLonerangerUsersReportSuccess, downloadUnverifiedUsersReportSuccess, fetchTransactionReportSuccess, downloadTransactionReportSuccess, filterUsersSuccess, fetchAllSubscriptionSuccess } from "../Actions";

export const fetchBookRequest = function* ({ payload }) {
  yield call(requestFunction, fetchBookReportSuccess, getBookReport, payload);
};

export const fetchBookreportss = function* () {
  yield takeEvery(FETCH_BOOK_REPORT, fetchBookRequest);
};


export const downloadBookReportRequest = function* ({ payload }) {
    yield call(requestFunction, downloadBookReportSuccess, downloadBookReportCSV, payload);
  };
  
  export const downloadBookReporttt = function* () {
    yield takeEvery(DOWNLOAD_BOOK_REPORT, downloadBookReportRequest);
  };


export const fetchUserRequest = function* ({ payload }) {
    yield call(requestFunction, fetchUsersReportSuccess, getUsersReport, payload);
  };
  
  export const fetchUsersReportss = function* () {
    yield takeEvery(FETCH_USERS_REPORT, fetchUserRequest);
  };
  

  export const fetchSubscriptionRequest = function* ({ payload }) {
    yield call(requestFunction, fetchSubscriptionReportSuccess, getSubscriptionReport, payload);
  };
  
  export const fetchSubReportss = function* () {
    yield takeEvery(FETCH_SUBSCRIPTION_REPORT, fetchSubscriptionRequest);
  };
  

  export const downloadSubReportRequest = function* ({ payload }) {
    yield call(requestFunction, downloadSubReportSuccess, downloadSubReportCSV, payload);
  };
  
  export const downloadSubReporttt = function* () {
    yield takeEvery(DOWNLOAD_SUB_REPORT, downloadSubReportRequest);
  };


  export const downloadAllUsersReportRequest = function* ({ payload }) {
    yield call(requestFunction, downloadAllUsersReportSuccess, downloadAllUsersReportCSV, payload);
  };
  
  export const downloadAllUsrReporttt = function* () {
    yield takeEvery(DOWNLOAD_ALL_USERS_REPORT, downloadAllUsersReportRequest);
  };



  export const downloadAllTrialUsersReportRequest = function* ({ payload }) {
    yield call(requestFunction, downloadTrialUsersReportSuccess, downloadTrialUsersReportCSV, payload);
  };
  
  export const downloadAllTrialUsrReporttt = function* () {
    yield takeEvery(DOWNLOAD_TRIAL_USERS_REPORT, downloadAllTrialUsersReportRequest);
  };


  export const downloadExpiredUsersReportRequest = function* ({ payload }) {
    yield call(requestFunction, downloadExpiredUsersReportSuccess, downloadExpiredUsersReportCSV, payload);
  };
  
  export const downloadExpiredUsrReporttt = function* () {
    yield takeEvery(DOWNLOAD_EXPIRED_USERS_REPORT, downloadExpiredUsersReportRequest);
  };




  export const downloadNeverSubUsersReportRequest = function* ({ payload }) {
    yield call(requestFunction, downloadNeverSubscribedUsersReportSuccess, downloadNeverSubscribedUsersReportCSV, payload);
  };
  
  export const downloadNeverSubUsrReporttt = function* () {
    yield takeEvery(DOWNLOAD_NEVERSUBSCRIBED_USERS_REPORT, downloadNeverSubUsersReportRequest);
  };



  export const downloadLoneUsersReportRequest = function* ({ payload }) {
    yield call(requestFunction, downloadLonerangerUsersReportSuccess, downloadLoneRangerUsersReportCSV, payload);
  };
  
  export const downloadLoneUsrReporttt = function* () {
    yield takeEvery(DOWNLOAD_LONERANGER_USERS_REPORT, downloadLoneUsersReportRequest);
  };



  export const downloadUnverifiedUsersReportRequest = function* ({ payload }) {
    yield call(requestFunction, downloadUnverifiedUsersReportSuccess, downloadUnverifiedUsersReportCSV, payload);
  };
  
  export const downloadUnverifiedUsrReporttt = function* () {
    yield takeEvery(DOWNLOAD_UNVERIFIED_USERS_REPORT, downloadUnverifiedUsersReportRequest);
  };



  export const fetchTransactionRequest = function* ({ payload }) {
    yield call(requestFunction, fetchTransactionReportSuccess, getTransactionReport, payload);
  };
  
  export const fetchTransreportss = function* () {
    yield takeEvery(FETCH_TRANSACTION_REPORT, fetchTransactionRequest);
  };


  export const downloadTransRequest = function* ({ payload }) {
    yield call(requestFunction, downloadTransactionReportSuccess, getTransactionReportCSV, payload);
  };
  
  export const downloadTransReporttt = function* () {
    yield takeEvery(DOWNLOAD_TRANSACTION_REPORT_CSV, downloadTransRequest);
  };
  


  export const fetchAllSubscriptionRequest = function* ({ payload }) {
    yield call(requestFunction, fetchAllSubscriptionSuccess, getAllSubscription, payload);
  };
  
  export const fetchAllSub = function* () {
    yield takeEvery(FETCH_ALL_SUBSCRIPTIONS, fetchAllSubscriptionRequest);
  };



  export const filterUserRequest = function* ({ payload }) {
    yield call(requestFunction, filterUsersSuccess, filterUserbyPlanOrStatus, payload);
  };
  
  export const filterUsrrr = function* () {
    yield takeEvery(FILTER_USERS, filterUserRequest);
  };




export default function* rootSaga() {
    yield all([
      
      fork(fetchBookreportss),
      fork(fetchUsersReportss),
      fork(fetchSubReportss),
      fork(downloadBookReporttt),
      fork(downloadSubReporttt),
      fork(downloadAllUsrReporttt),
      fork(downloadAllTrialUsrReporttt),
      fork(downloadExpiredUsrReporttt),
      fork(downloadNeverSubUsrReporttt),
      fork(downloadLoneUsrReporttt),
      fork(downloadUnverifiedUsrReporttt),
      fork(fetchTransreportss),
      fork(downloadTransReporttt),
      fork(fetchAllSub),
      fork(filterUsrrr)
   
    ]);
  }