import {
    FETCH_ALL_BLOGS_SUCCESS,
    FETCH_BLOG_BY_ID_SUCCESS,
    CREATE_BLOG_SUCCESS,
    RESTORE_CREATE_BLOG_INITIAL,
    UPDATE_BLOG_SUCCESS,
    RESTORE_UPDATE_BLOG_INITIAL,
    ADD_BLOG_URL_SUCCESS,
    RESTORE_ADD_BLOG_URL_INITIAL,
    DELETE_BLOG_SUCCESS,
    RESTORE_DELETE_BLOG_INITIAL
  } from "../ActionTypes";
  
  const INIT_STATE = {
    blogs: [],
    blogById: {},

    addBlogSuccess:false,
    addBlogFailure:false,
  
    editBlogSuccess:false,
    editBlogFailure:false,

    addBlogUrlSuccess:false,
    addBlogUrlFailure:false,

    deleteBlogSuccess:false,
    deleteBlogFailure:false,

  };
  
  const blogReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case FETCH_ALL_BLOGS_SUCCESS: {
        return {
          ...state,
          blogs: action.payload,
        };
      }
      case FETCH_BLOG_BY_ID_SUCCESS: {
        return {
          ...state,
          blogById: action.payload,
        };
      }


      case CREATE_BLOG_SUCCESS: {
        if (action.payload === true) {
          return {
            ...state,
            addBlogSuccess: true,
            addBlogFailure: false,
          };
        } else {
          return {
            ...state,
            addBlogSuccess: false,
            addBlogFailure: true,
          };
        }
      }
  
      case RESTORE_CREATE_BLOG_INITIAL: {
        return {
          ...state,
          addBlogSuccess: false,
          addBlogFailure: false,
        };
      }


      case UPDATE_BLOG_SUCCESS: {
        if (action.payload === true) {
          return {
            ...state,
            editBlogSuccess: true,
            editBlogFailure: false,
          };
        } else {
          return {
            ...state,
            editBlogSuccess: false,
            editBlogFailure: true,
          };
        }
      }
  
      case RESTORE_UPDATE_BLOG_INITIAL: {
        return {
          ...state,
          editBlogSuccess: false,
          editBlogFailure: false,
        };
      }


      case ADD_BLOG_URL_SUCCESS: {
        if (action.payload === true) {
          return {
            ...state,
            addBlogUrlSuccess: true,
            addBlogUrlFailure: false,
          };
        } else {
          return {
            ...state,
            addBlogUrlSuccess: false,
            addBlogUrlFailure: true,
          };
        }
      }
  
      case RESTORE_ADD_BLOG_URL_INITIAL: {
        return {
          ...state,
          addBlogUrlSuccess: false,
          addBlogUrlFailure: false,
        };
      }


      case DELETE_BLOG_SUCCESS: {
        if (action.payload === true) {
          return {
            ...state,
            deleteBlogSuccess: true,
            deleteBlogFailure: false,
          };
        } else {
          return {
            ...state,
            deleteBlogSuccess: false,
            deleteBlogFailure: true,
          };
        }
      }
  
      case RESTORE_DELETE_BLOG_INITIAL: {
        return {
          ...state,
          deleteBlogSuccess: false,
          deleteBlogFailure: false,
        };
      }


      default:
        return state;
    }
  };
  
  export default blogReducer;
  