import { 
  
  
    FETCH_ALL_PUBLISHERS,
    FETCH_ALL_PUBLISHERS_SUCCESS,
    EMPTY_PUBLISHERS,

    FETCH_PUBLISHERS_BY_ID,
    FETCH_PUBLISHERS_BY_ID_SUCCESS,


  FETCH_BOOKS_BY_PUBLISHER_ID,
  FETCH_BOOKS_BY_PUBLISHER_ID_SUCCESS,

  EDIT_PUBLISHER,
  EDIT_PUBLISHER_SUCCESS,
  RESTORE_EDIT_PUBLISHER_INITIAL,

  CREATE_PUBLISHER,
  CREATE_PUBLISHER_SUCCESS,
  RESTORE_CREATE_PUBLISHER_INITIAL,

  SEARCH_PUBLISHERS_BOOK,
  SEARCH_PUBLISHERS_BOOK_SUCCESS,

  EMPTY_PUBLISHERS_BOOKS,


  DELETE_PUBLISHER,
  DELETE_PUBLISHER_SUCCESS,
  RESTORE_DELETE_PUBLISHER_INITIAL,


FETCH_PUBLISHER_BOOKS,
FETCH_PUBLISHER_BOOKS_SUCCESS,
FETCH_PUBLISHER_BOOKS_PERFORMANCE,
FETCH_PUBLISHER_BOOKS_PERFORMANCE_SUCCESS



} from "../ActionTypes";
  
export const fetchPublishers = () => ({
  type: FETCH_ALL_PUBLISHERS,
});

export const fetchPublishersSuccess = (payload) => ({
  type: FETCH_ALL_PUBLISHERS_SUCCESS,
  payload,
});

export const emptyPublishers = () => ({
    type: EMPTY_PUBLISHERS,
  });


  export const createPublisher = ({ publisher_name }) => ({
    type: CREATE_PUBLISHER,
    payload: {
      publisher_name,
    },
  });
  
  export const createPublisherSuccess = (payload) => ({
    type: CREATE_PUBLISHER_SUCCESS,
    payload,
  });
  
  export const restoreCreatePublisherInitial = () => ({
    type: RESTORE_CREATE_PUBLISHER_INITIAL,
  });

  

  export const editPublisher = ({ publisher_name, id }) => ({
    type: EDIT_PUBLISHER,
    payload: {
      publisher_name,
      id
    },
  });
  
  export const editPublisherSuccess = (payload) => ({
    type: EDIT_PUBLISHER_SUCCESS,
    payload,
  });
  
  export const restoreEditPublisherInitial = () => ({
    type: RESTORE_EDIT_PUBLISHER_INITIAL,
  });



  export const deletePublisher = (id) => ({
    type: DELETE_PUBLISHER,
    payload: id
  });
  
  export const deletePublisherSuccess = (payload) => ({
    type: DELETE_PUBLISHER_SUCCESS,
    payload,
  });
  
  export const restoreDeletePublisherInitial = () => ({
      type: RESTORE_DELETE_PUBLISHER_INITIAL,
    });
  
  


  export const fetchBooksByPublisherId = (id) => ({
    type: FETCH_BOOKS_BY_PUBLISHER_ID,
    payload: id
  });
  
  export const fetchBooksByPublisherIdSuccess = (payload) => ({
    type: FETCH_BOOKS_BY_PUBLISHER_ID_SUCCESS,
    payload,
  });
  
  export const emptyPublishersBooks = () => ({
    type: EMPTY_PUBLISHERS_BOOKS,
  });

  export const fetchPublisherById = (id) => ({
    type: FETCH_PUBLISHERS_BY_ID,
    payload: id
  });
  
  export const fetchPublisherByIdSuccess = (payload) => ({
    type: FETCH_PUBLISHERS_BY_ID_SUCCESS,
    payload,
  });
  

  export const searchPublisherBooks = ({ search, id }) => ({
    type: SEARCH_PUBLISHERS_BOOK,
    payload: {
      search,
      id
    },
  });
  
  export const searchPublisherBooksSuccess = (payload) => ({
    type: SEARCH_PUBLISHERS_BOOK_SUCCESS,
    payload,
  });
  


  export const fetchPublishersBooks = () => ({
    type: FETCH_PUBLISHER_BOOKS,
  });
  
  export const fetchPublishersBooksSuccess = (payload) => ({
    type: FETCH_PUBLISHER_BOOKS_SUCCESS,
    payload,
  });


  
  export const fetchPublishersBooksPerformance = () => ({
    type: FETCH_PUBLISHER_BOOKS_PERFORMANCE,
  });
  
  export const fetchPublishersBooksPerformanceSuccess = (payload) => ({
    type: FETCH_PUBLISHER_BOOKS_PERFORMANCE_SUCCESS,
    payload,
  });