import api from "../Service";
import { toastr } from "react-redux-toastr";

const baseController = "api/admin/";

export const getAuthors = async (id) => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard/author/all?page=${id}`);
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          return response.data.data;
        }
      } else {
        toastr.error("An Error occured", "Could not retrieve authors");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};


export const getAuthorsNoPagination = async () => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.get(`${baseController}dashboard/book/authors/id`);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            return response.data.data;
          }
        } else {
          toastr.error("An Error occured", "Could not retrieve authors");
        }
      } catch (ex) {
        toastr.error("An Error occurred", "Please try again");
      }
    }
  };


export const getAuthorsById = async (id) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.get(`${baseController}dashboard/author/find/${id}`);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            return response.data.data;
          }
        } else {
          toastr.error("An Error occured", "Could not retrieve author details");
        }
      } catch (ex) {
        toastr.error("An Error occurred", "Please try again");
      }
    }
  };


export const getBestAuthors = async () => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.get(`${baseController}dashboard/author/best`);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            return response.data.data;
          }
        } else {
          toastr.error("An Error occured", "Could not retrieve authors");
        }
      } catch (ex) {
        toastr.error("An Error occurred", "Please try again");
      }
    }
  };
  

  export const deleteAuthorr = async (id) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.get(`${baseController}dashboard/author/delete/${id}`);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            toastr.success("Author Deleted Successfully", "");
            return response.data.status;
          }
        } else {
          toastr.error("An Error occured", "Could not delete author");
        }
      } catch (ex) {
        toastr.error("An Error occurred", "Please try again");
      }
    }
  };



  export const addAuthorr = async (body) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.post(`${baseController}dashboard/author/add`, body, {
            headers: {
                'content-type': 'multipart/form-data' 
          }
        });
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            toastr.success(`${response.data.message}`, "Success");
            return response.data.status;
          } else {
            toastr.error("Something went wrong", "Please try again");
            return response.data.status;
          }
        } else {
          toastr.error("An Error Occured", "Please try again");
        }
      } catch (ex) {
        toastr.error("An Error Occured", "Please try again");
            // console.log(ex.response.data)
            return ex.response.data.message
      }
    }
  };



  export const editAuthorr = async (body) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.post(`${baseController}dashboard/author/edit/${body.authorId}`, body.formData, {
            headers: {
                'content-type': 'multipart/form-data' 
          }
        });
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            toastr.success(`${response.data.message}`, "Success");
            return response.data.status;
          } else {
            toastr.error("Something went wrong", "Please try again");
            return response.data.status;
          }
        } else {
          toastr.error("An Error Occured", "Please try again");
        }
      } catch (ex) {
        toastr.error("An Error Occured", "Please try again");
            // console.log(ex.response.data)
            return ex.response.data.message
      }
    }
  };



  export const searchAuthor = async (body) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
        try {
            const response = await api.post(`${baseController}dashboard/author/search`, body);
            if (typeof response !== "undefined") {
              if (response.status === 200 && response.data.status === true) {
                return response.data.data;
              }
              else{
                toastr.error("Author not found", "Please check the author name used");
              }
            } else {
              toastr.error("An Error occured", "Could not retrieve author");
            }
          } catch (ex) {
            toastr.error("An Error occurred", "Please try again");
          }
    }
  };

