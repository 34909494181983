import { 
    

    FETCH_ALL_COUNTRIES_SUCCESS,
    FETCH_SONGS_SUCCESS,
    CREATE_SONG_SUCCESS,
    RESTORE_CREATE_SONG_INITIAL,
    EDIT_SONG_SUCCESS,
    RESTORE_EDIT_SONG_INITIAL,
    DELETE_SONG_SUCCESS,
    RESTORE_DELETE_SONG_INITIAL,
    FETCH_SONG_BY_ID_SUCCESS
  
  } from "../ActionTypes";
  
  const INIT_STATE = {
    allCountries: [],
    allSongs:[],
    addSongSuccess:false,
    addSongFailure:false,
    editSongSuccess:false,
    editSongFailure:false,
    deleteSongSuccess:false,
    deleteSongFailure:false,
    songById:{}

  }


  const miscReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case FETCH_ALL_COUNTRIES_SUCCESS: {
        return {
          ...state,
          allCountries: action.payload,
        };
      }  

      case FETCH_SONGS_SUCCESS: {
        return {
          ...state,
          allSongs: action.payload,
        };
      }

      case FETCH_SONG_BY_ID_SUCCESS: {
        return {
          ...state,
          songById: action.payload,
        };
      }

      case CREATE_SONG_SUCCESS: {
        if (action.payload === true) {
          return {
            ...state,
            addSongSuccess: true,
            addSongFailure: false,
          };
        } else {
          return {
            ...state,
            addSongSuccess: false,
            addSongFailure: true,
          };
        }
      }

      case RESTORE_CREATE_SONG_INITIAL: {
        return {
          ...state,
          addSongSuccess: false,
          addSongFailure: false,
        };
      }

      case EDIT_SONG_SUCCESS: {
        if (action.payload === true) {
          return {
            ...state,
            editSongSuccess: true,
            editSongFailure: false,
          };
        } else {
          return {
            ...state,
            editSongSuccess: false,
            editSongFailure: true,
          };
        }
      }

      case RESTORE_EDIT_SONG_INITIAL: {
        return {
          ...state,
          editSongSuccess: false,
          editSongFailure: false,
        };
      }


      case DELETE_SONG_SUCCESS: {
        if (action.payload === true) {
          return {
            ...state,
            deleteSongSuccess: true,
            deleteSongFailure: false,
          };
        } else {
          return {
            ...state,
            deleteSongSuccess: false,
            deleteSongFailure: true,
          };
        }
      }

      case RESTORE_DELETE_SONG_INITIAL: {
        return {
          ...state,
          deleteSongSuccess: false,
          deleteSongFailure: false,
        };
      }


      default:
        return state;
    }
  };
  
  export default miscReducer;