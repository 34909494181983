import api from "../Service";
import { toastr } from "react-redux-toastr";

const baseController = "api/admin/";

export const getPublishers = async () => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard/publishers/all`);
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          return response.data.data;
        }
      } else {
        toastr.error("An Error occured", "Could not retrieve publishers");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};


export const getPublishersById = async (id) => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard/publishers/show/${id}`);
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          return response.data.data;
        }
      } else {
        toastr.error("An Error occured", "Could not retrieve publisher");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};


export const getBooksByPublishersId = async (id) => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard/publishers/export/${id}`);
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          return response.data.data;
        }
      } else {
        toastr.error("An Error occured", "Could not retrieve book");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};


export const addPublisher = async (body) => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.post(`${baseController}dashboard/publishers/create`, body);
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          toastr.success(`${response.data.message}`, "Success");
          return response.data.status;
        } else {
          toastr.error(`${response.data.message}`, "Please try again");
          return response.data.status;
        }
      } else {
        toastr.error("An Error Occured", "Please try again");
      }
    } catch (ex) {
      toastr.error("An Error Occured", "Please try again");
          return ex.response.data.message
    }
  }
};



export const editPublisher = async (body) => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.post(`${baseController}dashboard/publishers/edit/${body.id}`, body);
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          toastr.success(`${response.data.message}`, "Success");
          return response.data.status;
        } else {
          toastr.error(`${response.data.message}`, "Please try again");
          return response.data.status;
        }
      } else {
        toastr.error("An Error Occured", "Please try again");
      }
    } catch (ex) {
      toastr.error("An Error Occured", "Please try again");
          return ex.response.data.message
    }
  }
};



export const searchPublisherBks = async (body) => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.post(`${baseController}dashboard/publishers/search/${body.id}`, body);
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          if(response.data.data.length !==0){
            toastr.success('Book retrieved Successfully', "");
          }
          else{
            toastr.warning('Book Not Found', "Please try again with another search term");
          }
          return response.data.data;
        } else {
          toastr.error('Something went wrong', "Please try again");
          return response.data.data;
        }
      } else {
        toastr.error("An Error Occured", "Please try again");
      }
    } catch (ex) {
      toastr.error("An Error Occured", "Please try again");
          return ex.response.data.message
    }
  }
};


export const deletePublisherr = async (id) => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard/publishers/delete/${id}`);
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          toastr.success("Publisher Deleted Successfully", "");
          return response.data.status;
        }
      } else {
        toastr.error("An Error occured", "Could not delete publisher");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};



export const getPublisherBooks = async () => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard/publisher/books`);
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          return response.data.data;
        }
      } else {
        toastr.error("An Error occured", "Could not retrieve books");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};



export const getPublisherBooksPerformance = async () => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard/publisher/books/detail`);
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          return response.data.data;
        }
      } else {
        toastr.error("An Error occured", "Could not retrieve book performance");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};