import { all, call, takeEvery, fork } from "redux-saga/effects";
import requestFunction from "../Utils/sagasGenericFunction";

import { FETCH_REFERRED_USER, FETCH_REFERRALS_BY_USER } from "../ActionTypes";

import { getReferredUsers, getReferralsByUser } from "../Api";

import { fetchReferralsByUsersSuccess, fetchReferredUsersSuccess } from "../Actions";

export const fetchReferredUsersRequest = function* ({ payload }) {
  yield call(requestFunction, fetchReferredUsersSuccess, getReferredUsers, payload);
};

export const fetchReffferdUsr = function* () {
  yield takeEvery(FETCH_REFERRED_USER, fetchReferredUsersRequest);
};


export const fetchReferralsByUserRequest = function* ({ payload }) {
    yield call(requestFunction, fetchReferralsByUsersSuccess, getReferralsByUser, payload);
  };
  
  export const fetchRefferalsByUsr = function* () {
    yield takeEvery(FETCH_REFERRALS_BY_USER, fetchReferralsByUserRequest);
  };


export default function* rootSaga() {
    yield all([
      
      fork(fetchReffferdUsr),
      fork(fetchRefferalsByUsr)
     
    ]);
  }
  