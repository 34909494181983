export const LOGIN = "login";
export const LOGIN_SUCCESS = "login_success";
export const RESTORE_LOGIN_INITIAL = "restore_login_initial";

export const FORGOT_PASSWORD = "forgot_password";
export const FORGOT_PASSWORD_SUCCESS = "forgot_password_success";
export const RESTORE_FORGOT_PASSWORD_INITIAL = "restore_forgot_password_initial";

export const RESET_PASSWORD = "reset_password";
export const RESET_PASSWORD_SUCCESS = "reset_password_success";
export const RESTORE_RESET_PASSWORD_INITIAL = "restore_reset_password_initial";


export const FETCH_DASHBOARD_DETAILS = "fetch_dashboard_details";
export const FETCH_DASHBOARD_DETAILS_SUCCESS = "fetch_dashboard_details_success"


export const FETCH_DASHBOARD_BOOK_DETAILS = "fetch_dashbaord_book_details"
export const FETCH_DASHBOARD_BOOK_DETAILS_SUCCESS = "fetch_dashbaord_book_details_success"

export const FETCH_DASHBOARD_USER_DETAILS = "fetch_dashbaord_user_details"
export const FETCH_DASHBOARD_USER_DETAILS_SUCCESS = "fetch_dashbaord_user_details_success"


export const FETCH_DASHBOARD_WITHDRAWAL_DETAILS = "fetch_dashbaord_withdrawal_details"
export const FETCH_DASHBOARD_WITHDRAWAL_DETAILS_SUCCESS = "fetch_dashbaord_withdrawal_details_success"



export const FETCH_WITHDRAWAL_REQUEST = "fetch_withdrawal_request";
export const FETCH_WITHDRAWAL_REQUEST_SUCCESS = "fetch_withdrawal_request_success";

export const ADD_MAX_WITHDRAWAL_LIMIT = "add_max_withdrawal_limit";
export const ADD_MAX_WITHDRAWAL_LIMIT_SUCCESS = "add_max_withdrawal_limit_success";
export const RESTORE_ADD_MAX_WITHDRAWAL_LIMIT_INITIAL = "restore_add_max_withdrawal_limit_initial";


export const ADD_MIN_WITHDRAWAL_LIMIT = "add_min_withdrawal_limit";
export const ADD_MIN_WITHDRAWAL_LIMIT_SUCCESS = "add_min_withdrawal_limit_success";
export const RESTORE_ADD_MIN_WITHDRAWAL_LIMIT_INITIAL = "restore_add_min_withdrawal_limit_initial";


export const FETCH_BOOK_REPORT = "fetch_book_report"
export const FETCH_BOOK_REPORT_SUCCESS = "fetch_book_report_success"


export const DOWNLOAD_BOOK_REPORT = "download_book_report"
export const DOWNLOAD_BOOK_REPORT_SUCCESS = "download_book_report_success"
export const RESTORE_DOWNLOAD_BOOK_REPORT_INITIAL = "restore_download_book_report_initial";


export const FETCH_USERS_REPORT = "fetch_users_report"
export const FETCH_USERS_REPORT_SUCCESS = "fetch_users_report_success"

export const FETCH_SUBSCRIPTION_REPORT = "fetch_subscription_report"
export const FETCH_SUBSCRIPTION_REPORT_SUCCESS = "fetch_subscription_report_success"

export const DOWNLOAD_SUB_REPORT = "download_sub_report"
export const DOWNLOAD_SUB_REPORT_SUCCESS = "download_sub_report_success"
export const RESTORE_DOWNLOAD_SUB_REPORT_INITIAL = "restore_download_sub_report_initial";


export const DOWNLOAD_ALL_USERS_REPORT = "download_all_users_report"
export const DOWNLOAD_ALL_USERS_REPORT_SUCCESS = "download_all_users_report_success"
export const RESTORE_DOWNLOAD_ALL_USERS_REPORT_INITIAL = "restore_download_all_users_report_initial";


export const DOWNLOAD_TRIAL_USERS_REPORT = "download_trial_users_report"
export const DOWNLOAD_TRIAL_USERS_REPORT_SUCCESS = "download_trial_users_report_success"
export const RESTORE_DOWNLOAD_TRIAL_USERS_REPORT_INITIAL = "restore_download_trial_users_report_initial";


export const DOWNLOAD_EXPIRED_USERS_REPORT = "download_expired_users_report"
export const DOWNLOAD_EXPIRED_USERS_REPORT_SUCCESS = "download_expired_users_report_success"
export const RESTORE_DOWNLOAD_EXPIRED_USERS_REPORT_INITIAL = "restore_download_expired_users_report_initial";

export const DOWNLOAD_NEVERSUBSCRIBED_USERS_REPORT = "download_neversubscribed_users_report"
export const DOWNLOAD_NEVERSUBSCRIBED_USERS_REPORT_SUCCESS = "download_neversubscribed_users_report_success"
export const RESTORE_DOWNLOAD_NEVERSUBSCRIBED_USERS_REPORT_INITIAL = "restore_download_neversubscribed_users_report_initial";


export const DOWNLOAD_LONERANGER_USERS_REPORT = "download_loneranger_users_report"
export const DOWNLOAD_LONERANGER_USERS_REPORT_SUCCESS = "download_loneranger_users_report_success"
export const RESTORE_DOWNLOAD_LONERANGER_USERS_REPORT_INITIAL = "restore_download_loneranger_users_report_initial";

export const DOWNLOAD_UNVERIFIED_USERS_REPORT = "download_unverified_users_report"
export const DOWNLOAD_UNVERIFIED_USERS_REPORT_SUCCESS = "download_unverified_users_report_success"
export const RESTORE_DOWNLOAD_UNVERIFIED_USERS_REPORT_INITIAL = "restore_download_unverified_users_report_initial";






export const SEND_NOTIFICATION = "send_notification";
export const SEND_NOTIFICATION_SUCCESS = "send_notification_success";
export const RESTORE_SEND_NOTIFICATION_INITIAL = "restore_send_notification_initial";


export const FETCH_ALL_AUTHORS = "fetch_all_authors";
export const FETCH_ALL_AUTHORS_SUCCESS = "fetch_all_authors_success";

export const FETCH_ALL_AUTHORS_NO_PAGINATION = "fetch_all_authors_no_pagination";
export const FETCH_ALL_AUTHORS_NO_PAGINATION_SUCCESS = "fetch_all_authors_no_pagination_success";


export const ADD_AUTHORS = "add_authors";
export const ADD_AUTHORS_SUCCESS = "add_authors_success"
export const RESTORE_ADD_AUTHORS_INITIAL = "restore_add_authors_initial";


export const EDIT_AUTHORS = "edit_authors";
export const EDIT_AUTHORS_SUCCESS = "edit_authors_success"
export const RESTORE_EDIT_AUTHORS_INITIAL = "restore_edit_authors_initial";


export const DELETE_AUTHORS = "delete_authors";
export const DELETE_AUTHORS_SUCCESS = "delete_authors_success"
export const RESTORE_DELETE_AUTHORS_INITIAL = "restore_delete_authors_initial";

export const FETCH_BEST_AUTHORS_CSV = "fetch_best_authors_csv";
export const FETCH_BEST_AUTHORS_CSV_SUCCESS = "fetch_best_authors_csv_success";


export const FETCH_AUTHORS_BY_ID = "fetch_authors_by_id";
export const FETCH_AUTHORS_BY_ID_SUCCESS = "fetch_authors_by_id_success";


export const SEARCH_AUTHOR = "search_author";
export const SEARCH_AUTHOR_SUCCESS = "search_author_success";


export const FETCH_ALL_GENRE = "fetch_all_genre";
export const FETCH_ALL_GENRE_SUCCESS = "fetch_all_genre_success";

export const FETCH_SUBGENRE = "fetch_all_subgenre";
export const FETCH_SUBGENRE_SUCCESS = "fetch_all_subgenre_success";
export const EMPTY_SUBGENRE = "empty_subgenre";

export const ADD_GENRE = "add_genre";
export const ADD_GENRE_SUCCESS = "add_genre_success"
export const RESTORE_ADD_GENRE_INITIAL = "restore_add_genre_initial";


export const EDIT_GENRE = "edit_genre";
export const EDIT_GENRE_SUCCESS = "edit_genre_success"
export const RESTORE_EDIT_GENRE_INITIAL = "restore_edit_genre_initial";

export const DELETE_GENRE = "delete_genre";
export const DELETE_GENRE_SUCCESS = "delete_genre_success"
export const RESTORE_DELETE_GENRE_INITIAL = "restore_delete_genre_initial";


export const ADD_SUBGENRE = "add_subgenre";
export const ADD_SUBGENRE_SUCCESS = "add_subgenre_success"
export const RESTORE_ADD_SUBGENRE_INITIAL = "restore_add_subgenre_initial";


export const EDIT_SUBGENRE = "edit_subgenre";
export const EDIT_SUBGENRE_SUCCESS = "edit_subgenre_success"
export const RESTORE_EDIT_SUBGENRE_INITIAL = "restore_edit_subgenre_initial";

export const DELETE_SUBGENRE = "delete_subgenre";
export const DELETE_SUBGENRE_SUCCESS = "delete_subgenre_success";
export const RESTORE_DELETE_SUBGENRE_INITIAL = "restore_delete_subgenre_initial";

export const FETCH_GENRE_BY_ID = "fetch_genre_by_id";
export const FETCH_GENRE_BY_ID_SUCCESS = "fetch_genre_by_id_success";


export const FETCH_SUBGENRE_BY_ID = "fetch_subgenre_by_id";
export const FETCH_SUBGENRE_BY_ID_SUCCESS = "fetch_subgenre_by_id_success";




export const FETCH_ALL_BOOKS = "fetch_all_books";
export const FETCH_ALL_BOOKS_SUCCESS = "fetch_all_books_success";
export const EMPTY_BOOKS = "empty_books";

export const FETCH_BOOKS_BY_ID = "fetch_books_by_id";
export const FETCH_BOOKS_BY_ID_SUCCESS = "fetch_books_by_id_success";


export const ADD_BOOKS = "add_books";
export const ADD_BOOKS_SUCCESS = "add_books_success"
export const RESTORE_ADD_BOOKS_INITIAL = "restore_add_books_initial";


export const EDIT_BOOKS = "edit_books";
export const EDIT_BOOKS_SUCCESS = "edit_books_success"
export const RESTORE_EDIT_BOOKS_INITIAL = "restore_edit_books_initial";


export const DELETE_BOOKS = "delete_books";
export const DELETE_BOOKS_SUCCESS = "delete_books_success"
export const RESTORE_DELETE_BOOKS_INITIAL = "restore_delete_books_initial";

export const SEARCH_BOOKS = "search_all_books";
export const SEARCH_BOOKS_SUCCESS = "search_all_books_success";



export const SEARCH_USERS = "search_all_users";
export const SEARCH_USERS_SUCCESS = "search_all_users_success";

export const FETCH_ALL_USERS = "fetch_all_users";
export const FETCH_ALL_USERS_SUCCESS = "fetch_all_users_success";
export const EMPTY_USERS = "empty_users";

export const CANCEL_SUBSCRIPTION = "cancel_subscription";
export const CANCEL_SUBSCRIPTION_SUCCESS = "cancel_subscription_success";
export const RESTORE_CANCEL_SUBSCRIPTION_INITIAL = "restore_cancel_subscription_initial";

export const REACTIVATE_SUBSCRIPTION = "reactivate_subscription";
export const REACTIVATE_SUBSCRIPTION_SUCCESS = "reactivate_subscription_success";
export const RESTORE_REACTIVATE_SUBSCRIPTION_INITIAL = "restore_reactivate_subscription_initial";


export const TRIAL_SUBSCRIPTION = "trial_subscription";
export const TRIAL_SUBSCRIPTION_SUCCESS = "trial_subscription_success";
export const RESTORE_TRIAL_SUBSCRIPTION_INITIAL = "restore_trial_subscription_initial";


export const LONE_RANGER_MONTHLY_SUBSCRIPTION = "lone_ranger_monthly_subscription";
export const LONE_RANGER_MONTHLY_SUBSCRIPTION_SUCCESS = "lone_ranger_monthly_subscription_success";
export const RESTORE_LONE_RANGER_MONTHLY_SUBSCRIPTION_INITIAL = "restore_lone_ranger_monthly_subscription_initial";


export const LONE_RANGER_QUARTERLY_SUBSCRIPTION = "lone_ranger_quarterly_subscription";
export const LONE_RANGER_QUARTERLY_SUBSCRIPTION_SUCCESS = "lone_ranger_quarterly_subscription_success";
export const RESTORE_LONE_RANGER_QUARTERLY_SUBSCRIPTION_INITIAL = "restore_lone_ranger_quarterly_subscription_initial";

export const LONE_RANGER_BIANNUAL_SUBSCRIPTION = "lone_ranger_biannual_subscription";
export const LONE_RANGER_BIANNUAL_SUBSCRIPTION_SUCCESS = "lone_ranger_bianuual_subscription_success";
export const RESTORE_LONE_RANGER_BIANNUAL_SUBSCRIPTION_INITIAL = "restore_lone_ranger_biannual_subscription_initial";


export const LONE_RANGER_YEARLY_SUBSCRIPTION = "lone_ranger_yearly_subscription";
export const LONE_RANGER_YEARLY_SUBSCRIPTION_SUCCESS = "lone_ranger_yearly_subscription_success";
export const RESTORE_LONE_RANGER_YEARLY_BIANNUAL_SUBSCRIPTION_INITIAL = "restore_lone_ranger_yearly_subscription_initial";


export const EXTEND_USER_SUBSCRIPTION = "extend_user_subscription";
export const EXTEND_USER_SUBSCRIPTION_SUCCESS = "extend_user_subscription_success";
export const RESTORE_EXTEND_USER_SUBSCRIPTION_INITIAL = "restore_extend_user_subscription_initial";


export const DELETE_USERS = "delete_all_users";
export const DELETE_USERS_SUCCESS = "delete_all_users_success";
export const RESTORE_DELETE_USER_INITIAL = "restore_delete_user_initial";

export const FETCH_ALL_COUNTRIES = "fetch_all_countries";
export const FETCH_ALL_COUNTRIES_SUCCESS = "fetch_all_countries_success";


export const FETCH_ALL_PUBLISHERS = "fetch_all_publishers";
export const FETCH_ALL_PUBLISHERS_SUCCESS = "fetch_all_publishers_success";
export const EMPTY_PUBLISHERS = "empty_publishers";

export const FETCH_PUBLISHERS_BY_ID = "fetch_publishers_by_id";
export const FETCH_PUBLISHERS_BY_ID_SUCCESS = "fetch_publishers_by_id_success";


export const FETCH_BOOKS_BY_PUBLISHER_ID = "fetch_books_by_publisher_id"
export const FETCH_BOOKS_BY_PUBLISHER_ID_SUCCESS = "fetch_books_by_publisher_id_success";
export const EMPTY_PUBLISHERS_BOOKS = "empty_publishers_books";

export const EDIT_PUBLISHER = "edit_publisher";
export const EDIT_PUBLISHER_SUCCESS = "edit_publisher_success";
export const RESTORE_EDIT_PUBLISHER_INITIAL = "restore_edit_publisher_initial";

export const CREATE_PUBLISHER = "create_publisher";
export const CREATE_PUBLISHER_SUCCESS = "create_publisher_success";
export const RESTORE_CREATE_PUBLISHER_INITIAL = "restore_create_publisher_initial";

export const DELETE_PUBLISHER = "delete_publisher";
export const DELETE_PUBLISHER_SUCCESS = "delete_publisher_success";
export const RESTORE_DELETE_PUBLISHER_INITIAL = "restore_delete_publisher_initial";


export const SEARCH_PUBLISHERS_BOOK = "search_publishers_book";
export const SEARCH_PUBLISHERS_BOOK_SUCCESS = "search_publishers_book_success";


export const FETCH_REFERRED_USER = "fetch_referred_user";
export const FETCH_REFERRED_USER_SUCCESS = "fetch_referred_user_success";

export const FETCH_REFERRALS_BY_USER = "fetch_referrals_by_user";
export const FETCH_REFERRALS_BY_USER_SUCCESS = "fetch_referrals_by_user_success";

export const FETCH_ALL_ADMIN_USERS = "fetch_all_admin_users";
export const FETCH_ALL_ADMIN_USERS_SUCCESS = "fetch_all_admin_users_success";

export const CREATE_ADMIN_USERS = "create_admin_users";
export const CREATE_ADMIN_USERS_SUCCESS = "create_admin_users_success";
export const RESTORE_CREATE_ADMIN_USERS_INITIAL = "restore_create_admin_users_initial";


export const EDIT_ADMIN_USERS = "edit_admin_users";
export const EDIT_ADMIN_USERS_SUCCESS = "edit_admin_users_success";
export const RESTORE_EDIT_ADMIN_USERS_INITIAL = "restore_edit_admin_users_initial";
export const DELETE_ADMIN_USERS = "delete_admin_users";
export const DELETE_ADMIN_USERS_SUCCESS = "delete_admin_users_success";
export const RESTORE_DELETE_ADMIN_USERS_INITIAL = "restore_delete_admin_users_initial";


export const FETCH_ADMIN_USERS_BY_ID = "fetch_admin_users_by_id";
export const FETCH_ADMIN_USERS_BY_ID_SUCCESS = "fetch_admin_users_by_id_success";



export const FETCH_TRANSACTION_REPORT = "fetch_transaction_report";
export const FETCH_TRANSACTION_REPORT_SUCCESS = "fetch_transaction_report_success"


export const DOWNLOAD_TRANSACTION_REPORT_CSV = "download_transaction_report_csv";
export const DOWNLOAD_TRANSACTION_REPORT_CSV_SUCCESS = "download_transaction_report_csv_success";
export const RESTORE_DOWNLOAD_TRANSACTION_REPORT_CSV_INITIAL = "restore_download_transaction_report_csv_initial";


export const FETCH_ALL_SUBSCRIPTIONS = "fetch_all_subscription";
export const FETCH_ALL_SUBSCRIPTIONS_SUCCESS = "fetch_all_subscription_success";
export const FILTER_USERS = "filter_user";
export const FILTER_USER_SUCCESS = "filter_user_success";


export const FETCH_SONGS = "fetch_songs";
export const FETCH_SONGS_SUCCESS = "fetch_songs_success";
export const CREATE_SONG = "create_song";
export const CREATE_SONG_SUCCESS = "create_song_success";
export const RESTORE_CREATE_SONG_INITIAL = "restore_create_song_initial";
export const EDIT_SONG = "edit_song";
export const EDIT_SONG_SUCCESS = "edit_song_success";
export const RESTORE_EDIT_SONG_INITIAL = "restore_edit_song_initial";
export const DELETE_SONG = "delete_song";
export const DELETE_SONG_SUCCESS = "delete_song_success";
export const RESTORE_DELETE_SONG_INITIAL = "restore_delete_song_initial";


export const FETCH_SONG_BY_ID = "fetch_song_by_id";
export const FETCH_SONG_BY_ID_SUCCESS = "fetch_song_by_id_success";

export const UPDATE_USER = "update_user";
export const UPDATE_USER_SUCCESS = "update_user_success";
export const RESTORE_UPDATE_USER_INITIAL = "restore_update_user_initial";

export const FETCH_USER_BY_ID = "fetch_user_by_id";
export const FETCH_USER_BY_ID_SUCCESS = "fetch_user_by_id_success";

export const FETCH_PUBLISHER_BOOKS = "fetch_publisher_books";
export const FETCH_PUBLISHER_BOOKS_SUCCESS = "fetch_publisher_books_success";
export const FETCH_PUBLISHER_BOOKS_PERFORMANCE = "fetch_publisher_books_performance";
export const FETCH_PUBLISHER_BOOKS_PERFORMANCE_SUCCESS = "fetch_publisher_books_performance_success";




export const FETCH_ALL_BLOGS = "fetch_all_blogs";
export const FETCH_ALL_BLOGS_SUCCESS = "fetch_all_blogs_success";

export const FETCH_BLOG_BY_ID = "fetch_blog_by_id";
export const FETCH_BLOG_BY_ID_SUCCESS = "fetch_blog_by_id_success";


export const UPDATE_BLOG = "update_blog";
export const UPDATE_BLOG_SUCCESS = "update_blog_success";
export const RESTORE_UPDATE_BLOG_INITIAL = "restore_update_blog_initial";

export const CREATE_BLOG = "create_blog";
export const CREATE_BLOG_SUCCESS = "create_blog_success";
export const RESTORE_CREATE_BLOG_INITIAL = "restore_create_blog_initial";

export const ADD_BLOG_URL = "add_blog_url";
export const ADD_BLOG_URL_SUCCESS = "add_blog_url_success";
export const RESTORE_ADD_BLOG_URL_INITIAL = "restore_add_blog_url_initial";


export const DELETE_BLOG = "delete_blog";
export const DELETE_BLOG_SUCCESS = "delete_blog_success";
export const RESTORE_DELETE_BLOG_INITIAL = "restore_delete_blog_initial";