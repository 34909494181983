import { all, call, takeEvery, fork } from "redux-saga/effects";
import requestFunction from "../Utils/sagasGenericFunction";

import {
  LOGIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from "../ActionTypes";


import {
    postlogin,
    postForgotPassword,
    postResetPassword,
  } from "../Api";


  import {
    userLoginSuccess,
    forgotPasswordSuccess,
    resetPasswordSuccess,
  } from "../Actions";


  export const loginRequest = function* ({ payload }) {
    yield call(requestFunction, userLoginSuccess, postlogin, payload);
  };
  
  export const userLoginn = function* () {
    yield takeEvery(LOGIN, loginRequest);
  };
  
  export const forgotPasswordRequest = function* ({ payload }) {
    yield call(requestFunction, forgotPasswordSuccess, postForgotPassword, payload);
  };
  
  export const forgotPass = function* () {
    yield takeEvery(FORGOT_PASSWORD, forgotPasswordRequest);
  };
  
  export const resetPasswordRequest = function* ({ payload }) {
    yield call(requestFunction, resetPasswordSuccess, postResetPassword, payload);
  };
  
  export const resetPass = function* () {
    yield takeEvery(RESET_PASSWORD, resetPasswordRequest);
  };



  export default function* rootSaga() {
    yield all([

        fork(userLoginn),
        fork(forgotPass),
        fork(resetPass),

    ]);
}

