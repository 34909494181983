import { 
  FETCH_DASHBOARD_DETAILS_SUCCESS,
  FETCH_WITHDRAWAL_REQUEST_SUCCESS,
  ADD_MAX_WITHDRAWAL_LIMIT_SUCCESS,
  RESTORE_ADD_MAX_WITHDRAWAL_LIMIT_INITIAL,
  ADD_MIN_WITHDRAWAL_LIMIT_SUCCESS,
  RESTORE_ADD_MIN_WITHDRAWAL_LIMIT_INITIAL,

  FETCH_DASHBOARD_BOOK_DETAILS_SUCCESS,
  FETCH_DASHBOARD_USER_DETAILS_SUCCESS,
  FETCH_DASHBOARD_WITHDRAWAL_DETAILS_SUCCESS

} from "../ActionTypes";

const INIT_STATE = {
  dashboardDetails: {},
  dashboardUserDetails: {},
  dashboardBookDetails:{},
  dashboardWithdrawalDetails:{},
  withdrawalRequests:[],

  addMaxLimitSuccess:false,
  addMaxLimitFailure:false,

  addMinLimitSuccess:false,
  addMinLimitFailure:false,



};

const dashboardReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_DETAILS_SUCCESS: {
      return {
        ...state,
        dashboardDetails: action.payload,
      };
    }

    case FETCH_DASHBOARD_BOOK_DETAILS_SUCCESS: {
      return {
        ...state,
        dashboardBookDetails: action.payload,
      };
    }

    case FETCH_DASHBOARD_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        dashboardUserDetails: action.payload,
      };
    }

    case FETCH_DASHBOARD_WITHDRAWAL_DETAILS_SUCCESS: {
      return {
        ...state,
        dashboardWithdrawalDetails: action.payload,
      };
    }

    case FETCH_WITHDRAWAL_REQUEST_SUCCESS: {
      return {
        ...state,
        withdrawalRequests: action.payload,
      };
    }

    case ADD_MAX_WITHDRAWAL_LIMIT_SUCCESS: {
      if (action.payload === true) {
        return {
          ...state,
          addMaxLimitSuccess: true,
          addMaxLimitFailure: false,
        };
      } else {
        return {
          ...state,
          addMaxLimitSuccess: false,
          addMaxLimitFailure: true,
        };
      }
    }

    case RESTORE_ADD_MAX_WITHDRAWAL_LIMIT_INITIAL: {
      return {
        ...state,
        addMaxLimitSuccess: false,
        addMaxLimitFailure: false,
      };
    }

    case ADD_MIN_WITHDRAWAL_LIMIT_SUCCESS: {
      if (action.payload === true) {
        return {
          ...state,
          addMinLimitSuccess: true,
          addMinLimitFailure: false,
        };
      } else {
        return {
          ...state,
          addMinLimitSuccess: false,
          addMinLimitFailure: true,
        };
      }
    }

    case RESTORE_ADD_MIN_WITHDRAWAL_LIMIT_INITIAL: {
      return {
        ...state,
        addMinLimitSuccess: false,
        addMinLimitFailure: false,
      };
    }


    default:
      return state;
  }
};

export default dashboardReducer;
