import { 
    

    FETCH_REFERRED_USER_SUCCESS,
    FETCH_REFERRALS_BY_USER_SUCCESS

  
  } from "../ActionTypes";
  
  const INIT_STATE = {
    referredUser: {},
    referralsByUser:[]
  }


  const referralReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case FETCH_REFERRED_USER_SUCCESS: {
        return {
          ...state,
          referredUser: action.payload,
        };
      }  

      case FETCH_REFERRALS_BY_USER_SUCCESS: {
        return {
          ...state,
          referralsByUser: action.payload,
        };
      }

      default:
        return state;
    }
  };
  
  export default referralReducer;