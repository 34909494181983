import api from "../Service";
import { toastr } from "react-redux-toastr";

const baseController = "api/admin/";

export const getGenres = async () => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard/genre/all`);
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          return response.data.data;
        }
      } else {
        toastr.error("An Error occured", "Could not retrieve genre");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};


export const getSubGenre = async (id) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.get(`${baseController}dashboard/genre/sub/${id}`);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            return response.data.data;
          }
        } else {
          toastr.error("An Error occured", "Could not retrieve author details");
        }
      } catch (ex) {
        toastr.error("An Error occurred", "Please try again");
      }
    }
  };


  export const addGenre = async (body) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.post(`${baseController}dashboard/genre/add`, body);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            toastr.success(`${response.data.message}`, "Success");
            return response.data.status;
          } else {
            toastr.error(`${response.data.message}`, "Please try a different genre");
            return response.data.status;
          }
        } else {
          toastr.error("An Error Occured", "Please try again");
        }
      } catch (ex) {
        toastr.error("An Error Occured", "Please try again");
            return ex.response.data.message
      }
    }
  };



  export const editGenre = async (body) => {
    // debugger
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.post(`${baseController}dashboard/genre/edit/${body.id}`, body);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            toastr.success(`${response.data.message}`, "Success");
            return response.data.status;
          } else {
            toastr.error("Something went wrong", "Please try again");
            return response.data.status;
          }
        } else {
          toastr.error("An Error Occured", "Please try again");
        }
      } catch (ex) {
        toastr.error("An Error Occured", "Please try again");
            return ex.response.data.message
      }
    }
  };



  export const deleteGenre = async (id) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.get(`${baseController}dashboard/genre/delete/${id}`);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            toastr.success("Genre Deleted Successfully", "");
            return response.data.status;
          }
        } else {
          toastr.error("An Error occured", "Could not delete genre");
        }
      } catch (ex) {
        toastr.error("An Error occurred", "Please try again");
      }
    }
  };


  export const addSubGenre = async (body) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.post(`${baseController}dashboard/genre/add/sub/${body.id}`, body);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            toastr.success(`${response.data.message}`, "Success");
            return response.data.status;
          } else {
            toastr.error("Something went wrong", "Please try again");
            return response.data.status;
          }
        } else {
          toastr.error("An Error Occured", "Please try again");
        }
      } catch (ex) {
        toastr.error("An Error Occured", "Please try again");
            return ex.response.data.message
      }
    }
  };



  export const editSubGenre = async (body) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.post(`${baseController}dashboard/genre/sub/edit/${body.id}`, body);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            toastr.success(`${response.data.message}`, "Success");
            return response.data.status;
          } else {
            toastr.error("Something went wrong", "Please try again");
            return response.data.status;
          }
        } else {
          toastr.error("An Error Occured", "Please try again");
        }
      } catch (ex) {
        toastr.error("An Error Occured", "Please try again");
            return ex.response.data.message
      }
    }
  };

  export const deleteSubGenre = async (id) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.get(`${baseController}dashboard/genre/sub/delete/${id}`);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            toastr.success("Subgenre Deleted Successfully", "");
            return response.data.status;
          }
        } else {
          toastr.error("An Error occured", "Could not delete subgenre");
        }
      } catch (ex) {
        toastr.error("An Error occurred", "Please try again");
      }
    }
  };


  export const getGenreById = async (id) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.get(`${baseController}dashboard/genre/get/${id}`);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            return response.data.data;
          }
        } else {
          toastr.error("An Error occured", "Could not retrieve genre details");
        }
      } catch (ex) {
        toastr.error("An Error occurred", "Please try again");
      }
    }
  };


  export const getSubGenreById = async (id) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.get(`${baseController}dashboard/genre/sub/get/${id}`);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            return response.data.data;
          }
        } else {
          toastr.error("An Error occured", "Could not retrieve subgenre details");
        }
      } catch (ex) {
        toastr.error("An Error occurred", "Please try again");
      }
    }
  };