import { 
  
  
    FETCH_ALL_COUNTRIES,
    FETCH_ALL_COUNTRIES_SUCCESS,
    FETCH_SONGS,
    FETCH_SONGS_SUCCESS,
    CREATE_SONG,
    CREATE_SONG_SUCCESS,
    RESTORE_CREATE_SONG_INITIAL,
    EDIT_SONG,
    EDIT_SONG_SUCCESS,
    RESTORE_EDIT_SONG_INITIAL,
    DELETE_SONG,
    DELETE_SONG_SUCCESS,
    RESTORE_DELETE_SONG_INITIAL,
    FETCH_SONG_BY_ID,
    FETCH_SONG_BY_ID_SUCCESS
} from "../ActionTypes";
  
export const fetchCountries = () => ({
  type: FETCH_ALL_COUNTRIES,
});

export const fetchCountriesSuccess = (payload) => ({
  type: FETCH_ALL_COUNTRIES_SUCCESS,
  payload,
});


export const fetchSongs = () => ({
  type: FETCH_SONGS,
});

export const fetchSongsSuccess = (payload) => ({
  type: FETCH_SONGS_SUCCESS,
  payload,
});


export const addSong = (payload) => ({
  type: CREATE_SONG,
  payload
});

export const addSongSuccess = (payload) => ({
  type: CREATE_SONG_SUCCESS,
  payload,
});
  
export const restoreAddSongInitial = () => ({
  type: RESTORE_CREATE_SONG_INITIAL,
});


export const editSong = ({formData, songId}) => ({
  type: EDIT_SONG,
  payload :{
      formData,
      songId
  }
});

export const editSongSuccess = (payload) => ({
  type: EDIT_SONG_SUCCESS,
  payload,
});

export const restoreEditSongInitial = () => ({
  type: RESTORE_EDIT_SONG_INITIAL,
});



export const deleteSong = (id) => ({
  type: DELETE_SONG,
  payload: id,
});

export const deleteSongSuccess = (payload) => ({
  type: DELETE_SONG_SUCCESS,
  payload,
});

export const restoreDeleteSongInitial = () => ({
  type: RESTORE_DELETE_SONG_INITIAL,
});



export const fetchSongById = (id) => ({
  type: FETCH_SONG_BY_ID,
  payload: id,
});

export const fetchSongByIdSuccess = (payload) => ({
  type: FETCH_SONG_BY_ID_SUCCESS,
  payload,
});