import { all, call, takeEvery, fork } from "redux-saga/effects";
import requestFunction from "../Utils/sagasGenericFunction";

import { FETCH_DASHBOARD_DETAILS, FETCH_WITHDRAWAL_REQUEST, ADD_MAX_WITHDRAWAL_LIMIT, ADD_MIN_WITHDRAWAL_LIMIT, FETCH_DASHBOARD_BOOK_DETAILS, FETCH_DASHBOARD_USER_DETAILS, FETCH_DASHBOARD_WITHDRAWAL_DETAILS } from "../ActionTypes";

import { getDashboardDetails, getWithdrawalRequest, addMaxLimit, addMinLimit, getDashboardBookDetails, getDashboardUserDetails, getDashboardWithdrawalDetails } from "../Api";

import { fetchDashboardDetailsSuccess, fetchWithdrawalRequestSuccess, addMaxWithdrawalLimitSuccess, addMinWithdrawalLimitSuccess, fetchDashboardBookDetailsSuccess, fetchDashboardUserDetailsSuccess, fetchDashboardWithdrawalDetailsSuccess } from "../Actions";

export const fetchDashboardRequest = function* ({ payload }) {
  yield call(requestFunction, fetchDashboardDetailsSuccess, getDashboardDetails, payload);
};

export const fetchDashb = function* () {
  yield takeEvery(FETCH_DASHBOARD_DETAILS, fetchDashboardRequest);
};


export const fetchDashboardBookRequest = function* ({ payload }) {
  yield call(requestFunction, fetchDashboardBookDetailsSuccess, getDashboardBookDetails, payload);
};

export const fetchDashbBkDetails = function* () {
  yield takeEvery(FETCH_DASHBOARD_BOOK_DETAILS, fetchDashboardBookRequest);
};



export const fetchDashboardUserRequest = function* ({ payload }) {
  yield call(requestFunction, fetchDashboardUserDetailsSuccess, getDashboardUserDetails, payload);
};

export const fetchDashbUsrDtals = function* () {
  yield takeEvery(FETCH_DASHBOARD_USER_DETAILS, fetchDashboardUserRequest);
};



export const fetchDashboardWithdrawalRequest = function* ({ payload }) {
  yield call(requestFunction, fetchDashboardWithdrawalDetailsSuccess, getDashboardWithdrawalDetails, payload);
};

export const fetchDashbWithdrwalDtals = function* () {
  yield takeEvery(FETCH_DASHBOARD_WITHDRAWAL_DETAILS, fetchDashboardWithdrawalRequest);
};



export const fetchWithdrawalRequest = function* ({ payload }) {
  yield call(requestFunction, fetchWithdrawalRequestSuccess, getWithdrawalRequest, payload);
};

export const fetchWithdrawal = function* () {
  yield takeEvery(FETCH_WITHDRAWAL_REQUEST, fetchWithdrawalRequest);
};



export const addMaxWitdrawalLimitRequest = function* ({ payload }) {
  yield call(requestFunction, addMaxWithdrawalLimitSuccess, addMaxLimit, payload);
};

export const addMaxxLimitt = function* () {
  yield takeEvery(ADD_MAX_WITHDRAWAL_LIMIT, addMaxWitdrawalLimitRequest);
};



export const addMinWitdrawalLimitRequest = function* ({ payload }) {
  yield call(requestFunction, addMinWithdrawalLimitSuccess, addMinLimit, payload);
};

export const addMinnLimitt = function* () {
  yield takeEvery(ADD_MIN_WITHDRAWAL_LIMIT, addMinWitdrawalLimitRequest);
};


export default function* rootSaga() {
  yield all([
    
    fork(fetchDashb),
    fork(fetchWithdrawal),
    fork(addMaxxLimitt),
    fork(addMinnLimitt),
    fork(fetchDashbBkDetails),
    fork(fetchDashbUsrDtals),
    fork(fetchDashbWithdrwalDtals),
  ]);
}
