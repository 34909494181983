import { 
 
    FETCH_BOOK_REPORT_SUCCESS,
    FETCH_SUBSCRIPTION_REPORT_SUCCESS,
    FETCH_USERS_REPORT_SUCCESS,
    DOWNLOAD_BOOK_REPORT_SUCCESS,
    RESTORE_DOWNLOAD_BOOK_REPORT_INITIAL,

    DOWNLOAD_SUB_REPORT_SUCCESS,
    RESTORE_DOWNLOAD_SUB_REPORT_INITIAL,

    DOWNLOAD_ALL_USERS_REPORT_SUCCESS,
    RESTORE_DOWNLOAD_ALL_USERS_REPORT_INITIAL,
    DOWNLOAD_TRIAL_USERS_REPORT_SUCCESS,
    RESTORE_DOWNLOAD_TRIAL_USERS_REPORT_INITIAL,
    DOWNLOAD_EXPIRED_USERS_REPORT_SUCCESS,
    RESTORE_DOWNLOAD_EXPIRED_USERS_REPORT_INITIAL,
    DOWNLOAD_NEVERSUBSCRIBED_USERS_REPORT_SUCCESS,
    RESTORE_DOWNLOAD_NEVERSUBSCRIBED_USERS_REPORT_INITIAL,
    DOWNLOAD_LONERANGER_USERS_REPORT_SUCCESS,
    RESTORE_DOWNLOAD_LONERANGER_USERS_REPORT_INITIAL,
    DOWNLOAD_UNVERIFIED_USERS_REPORT_SUCCESS,
    RESTORE_DOWNLOAD_UNVERIFIED_USERS_REPORT_INITIAL,

    FETCH_TRANSACTION_REPORT_SUCCESS,
    DOWNLOAD_TRANSACTION_REPORT_CSV_SUCCESS,
    RESTORE_DOWNLOAD_TRANSACTION_REPORT_CSV_INITIAL,

    FETCH_ALL_SUBSCRIPTIONS_SUCCESS,
    FILTER_USER_SUCCESS
  
  } from "../ActionTypes";
  
  const INIT_STATE = {
    booksReport: [],
    usersReport:[],
    subscriptionReport:[],
    booksReportDownload: null,
    subReportDownload: null,

    allUsersReportDownload: null,
    trialUsersReportDownload: null,
    expiredUsersReportDownload: null,
    neverSubscribedUserReportDownload: null,
    lonerangerUsersReportDownload: null,
    unverifiedUsersReportDownload: null,

    transactionReport: [],
    transactionReportDownload: null,


    allSubscriptions:[],
    filteredUser:{}




  };
  
  const reportReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case FETCH_BOOK_REPORT_SUCCESS: {
        return {
          ...state,
          booksReport: action.payload,
        };
      }

      case DOWNLOAD_BOOK_REPORT_SUCCESS: {
        return {
          ...state,
          booksReportDownload: action.payload,
        };
      }


      case RESTORE_DOWNLOAD_BOOK_REPORT_INITIAL: {
        return {
          ...state,
          booksReportDownload: null,
        };
      }


      case DOWNLOAD_SUB_REPORT_SUCCESS: {
        return {
          ...state,
          subReportDownload: action.payload,
        };
      }


      case RESTORE_DOWNLOAD_SUB_REPORT_INITIAL: {
        return {
          ...state,
          subReportDownload: null,
        };
      }


  
      case FETCH_USERS_REPORT_SUCCESS: {
        return {
          ...state,
          usersReport: action.payload,
        };
      }

      case FETCH_SUBSCRIPTION_REPORT_SUCCESS: {
        return {
          ...state,
          subscriptionReport: action.payload,
        };
      }

      case DOWNLOAD_ALL_USERS_REPORT_SUCCESS: {
        return {
          ...state,
          allUsersReportDownload: action.payload,
        };
      }


      case RESTORE_DOWNLOAD_ALL_USERS_REPORT_INITIAL: {
        return {
          ...state,
          allUsersReportDownload: null,
        };
      }



      case DOWNLOAD_TRIAL_USERS_REPORT_SUCCESS: {
        return {
          ...state,
          trialUsersReportDownload: action.payload,
        };
      }


      case RESTORE_DOWNLOAD_TRIAL_USERS_REPORT_INITIAL: {
        return {
          ...state,
          trialUsersReportDownload: null,
        };
      }


      case DOWNLOAD_EXPIRED_USERS_REPORT_SUCCESS: {
        return {
          ...state,
          expiredUsersReportDownload: action.payload,
        };
      }


      case RESTORE_DOWNLOAD_EXPIRED_USERS_REPORT_INITIAL: {
        return {
          ...state,
          expiredUsersReportDownload: null,
        };
      }


      case DOWNLOAD_NEVERSUBSCRIBED_USERS_REPORT_SUCCESS: {
        return {
          ...state,
          neverSubscribedUserReportDownload: action.payload,
        };
      }


      case RESTORE_DOWNLOAD_NEVERSUBSCRIBED_USERS_REPORT_INITIAL: {
        return {
          ...state,
          neverSubscribedUserReportDownload: null,
        };
      }



      case DOWNLOAD_LONERANGER_USERS_REPORT_SUCCESS: {
        return {
          ...state,
          lonerangerUsersReportDownload: action.payload,
        };
      }


      case RESTORE_DOWNLOAD_LONERANGER_USERS_REPORT_INITIAL: {
        return {
          ...state,
          lonerangerUsersReportDownload: null,
        };
      }



      case DOWNLOAD_UNVERIFIED_USERS_REPORT_SUCCESS: {
        return {
          ...state,
          unverifiedUsersReportDownload: action.payload,
        };
      }


      case RESTORE_DOWNLOAD_UNVERIFIED_USERS_REPORT_INITIAL: {
        return {
          ...state,
          unverifiedUsersReportDownload: null,
        };
      }


      case FETCH_TRANSACTION_REPORT_SUCCESS: {
        return {
          ...state,
          transactionReport: action.payload,
        };
      }

      case DOWNLOAD_TRANSACTION_REPORT_CSV_SUCCESS: {
        return {
          ...state,
          transactionReportDownload: action.payload,
        };
      }


      case RESTORE_DOWNLOAD_TRANSACTION_REPORT_CSV_INITIAL: {
        return {
          ...state,
          transactionReportDownload: null,
        };
      }

      case FILTER_USER_SUCCESS: {
        return {
          ...state,
          filteredUser: action.payload,
        };
      }

      case FETCH_ALL_SUBSCRIPTIONS_SUCCESS: {
        return {
          ...state,
          allSubscriptions: action.payload,
        };
      }


      default:
        return state;
    }
  };
  
  export default reportReducer;
  