import { 
  
  
    FETCH_ALL_BOOKS,
    FETCH_ALL_BOOKS_SUCCESS,
    FETCH_BOOKS_BY_ID,
    FETCH_BOOKS_BY_ID_SUCCESS,
    ADD_BOOKS,
    ADD_BOOKS_SUCCESS,
    RESTORE_ADD_BOOKS_INITIAL,
    EDIT_BOOKS,
    EDIT_BOOKS_SUCCESS,
    RESTORE_EDIT_BOOKS_INITIAL,
    DELETE_BOOKS,
    DELETE_BOOKS_SUCCESS,
    RESTORE_DELETE_BOOKS_INITIAL,
    SEARCH_BOOKS,
    SEARCH_BOOKS_SUCCESS
  } from "../ActionTypes";
  
  export const fetchBooks = (id) => ({
    type: FETCH_ALL_BOOKS,
    payload: id
  });
  
  export const fetchBooksSuccess = (payload) => ({
    type: FETCH_ALL_BOOKS_SUCCESS,
    payload,
  });


  export const fetchBooksById = (id) => ({
    type: FETCH_BOOKS_BY_ID,
    payload: id
  });
  
  export const fetchBooksByIdSuccess = (payload) => ({
    type: FETCH_BOOKS_BY_ID_SUCCESS,
    payload,
  });

  
  export const addBooks = (payload) => ({
    type: ADD_BOOKS,
    payload
  });
  
  export const addBooksSuccess = (payload) => ({
    type: ADD_BOOKS_SUCCESS,
    payload,
  });
  
  export const restoreAddBooksInitial = () => ({
    type: RESTORE_ADD_BOOKS_INITIAL,
  });



  export const editBooks = ({formData, bookId}) => ({
    type: EDIT_BOOKS,
    payload :{
        formData,
        bookId
    }
  });
  
  export const editBooksSuccess = (payload) => ({
    type: EDIT_BOOKS_SUCCESS,
    payload,
  });
  
  export const restoreEditBooksInitial = () => ({
    type: RESTORE_EDIT_BOOKS_INITIAL,
  });


  export const deleteBooks = (id) => ({
    type: DELETE_BOOKS,
    payload: id,
  });
  
  export const deleteBooksSuccess = (payload) => ({
    type: DELETE_BOOKS_SUCCESS,
    payload,
  });
  
  export const restoreDeleteBooksInitial = () => ({
    type: RESTORE_DELETE_BOOKS_INITIAL,
  });



  export const searchBooks = ({search}) => ({
    type: SEARCH_BOOKS,
    payload :{
        search
    }
  });
  
  export const searchBooksSuccess = (payload) => ({
    type: SEARCH_BOOKS_SUCCESS,
    payload,
  });