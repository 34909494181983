import { all, call, takeEvery, fork } from "redux-saga/effects";
import requestFunction from "../Utils/sagasGenericFunction";

import { FETCH_ALL_USERS, CANCEL_SUBSCRIPTION, TRIAL_SUBSCRIPTION, LONE_RANGER_MONTHLY_SUBSCRIPTION, LONE_RANGER_QUARTERLY_SUBSCRIPTION, LONE_RANGER_BIANNUAL_SUBSCRIPTION, LONE_RANGER_YEARLY_SUBSCRIPTION, EXTEND_USER_SUBSCRIPTION, REACTIVATE_SUBSCRIPTION, SEARCH_USERS, DELETE_USERS, UPDATE_USER, FETCH_USER_BY_ID } from "../ActionTypes";

import { getUsers, cancelSub, trialSub, loneRangerMonthlySub, loneRangerQuaterlySub, loneRangerBiannualSub, loneRangerYearlySub, extendUserSub, reactivateSub, searchUsers, deleteAUser, updateUserDetails, getUserByUserId } from "../Api";

import { fetchUsersSuccess, cancelSubscriptionSuccess, activateTrialSubscriptionSuccess, activateLoanRangerMonthlySubscriptionSuccess, activateLoanRangerQuartlySubscriptionSuccess, activateLoanRangerBiannualSubscriptionSuccess, activateLoanRangerYearlySubscriptionSuccess, extendUserSubscriptionSuccess, reactivateSubscriptionSuccess, searchUsersSuccess, deleteUsersSuccess, updateUserSuccess, fetchUserByIdSuccess } from "../Actions";

export const fetchUsersRequest = function* ({ payload }) {
  yield call(requestFunction, fetchUsersSuccess, getUsers, payload);
};

export const fetchAllUsr = function* () {
  yield takeEvery(FETCH_ALL_USERS, fetchUsersRequest);
};



export const searchUsersRequest = function* ({ payload }) {
    yield call(requestFunction, searchUsersSuccess, searchUsers, payload);
  };
  
  export const searchAllUsr = function* () {
    yield takeEvery(SEARCH_USERS, searchUsersRequest);
  };


export const cancelSubRequest = function* ({ payload }) {
    yield call(requestFunction, cancelSubscriptionSuccess, cancelSub, payload);
  };
  
  export const canclSubs = function* () {
    yield takeEvery(CANCEL_SUBSCRIPTION, cancelSubRequest);
  };


  export const reactivateSubRequest = function* ({ payload }) {
    yield call(requestFunction, reactivateSubscriptionSuccess, reactivateSub, payload);
  };
  
  export const reactivateSubs = function* () {
    yield takeEvery(REACTIVATE_SUBSCRIPTION, reactivateSubRequest);
  };

  

export const activateTrialSubRequest = function* ({ payload }) {
  yield call(requestFunction, activateTrialSubscriptionSuccess, trialSub, payload);
};

export const activateTriallSubb = function* () {
  yield takeEvery(TRIAL_SUBSCRIPTION, activateTrialSubRequest);
};



export const loanRangerMonthlyRequest = function* ({ payload }) {
  yield call(requestFunction, activateLoanRangerMonthlySubscriptionSuccess, loneRangerMonthlySub, payload);
};

export const loneRangerMnthlySubs = function* () {
  yield takeEvery(LONE_RANGER_MONTHLY_SUBSCRIPTION, loanRangerMonthlyRequest);
};



export const loanRangerQuarterlyRequest = function* ({ payload }) {
    yield call(requestFunction, activateLoanRangerQuartlySubscriptionSuccess, loneRangerQuaterlySub, payload);
  };
  
  export const loanRangerQtr = function* () {
    yield takeEvery(LONE_RANGER_QUARTERLY_SUBSCRIPTION, loanRangerQuarterlyRequest);
  };
  


  export const LoanRangerBiannualRequest = function* ({ payload }) {
    yield call(requestFunction, activateLoanRangerBiannualSubscriptionSuccess, loneRangerBiannualSub, payload);
  };
  
  export const loanRangerBiannl = function* () {
    yield takeEvery(LONE_RANGER_BIANNUAL_SUBSCRIPTION, LoanRangerBiannualRequest);
  };
  


  
  export const LoanRangerYearlyRequest = function* ({ payload }) {
    yield call(requestFunction, activateLoanRangerYearlySubscriptionSuccess, loneRangerYearlySub, payload);
  };
  
  export const loanRangerYrl = function* () {
    yield takeEvery(LONE_RANGER_YEARLY_SUBSCRIPTION, LoanRangerYearlyRequest);
  };


  export const extendUserRequest = function* ({ payload }) {
    yield call(requestFunction, extendUserSubscriptionSuccess, extendUserSub, payload);
  };
  
  export const extendUsrSubs = function* () {
    yield takeEvery(EXTEND_USER_SUBSCRIPTION, extendUserRequest);
  };



  export const deleteUsersRequest = function* ({ payload }) {
    yield call(requestFunction, deleteUsersSuccess, deleteAUser, payload);
  };
  
  export const deleteUsr = function* () {
    yield takeEvery(DELETE_USERS, deleteUsersRequest);
  };

  export const fetchUserByIdRequest = function* ({ payload }) {
    yield call(requestFunction, fetchUserByIdSuccess, getUserByUserId, payload);
  };
  
  export const fetchUsrById = function* () {
    yield takeEvery(FETCH_USER_BY_ID, fetchUserByIdRequest);
  };

  export const updateUserRequest = function* ({ payload }) {
    yield call(requestFunction, updateUserSuccess, updateUserDetails, payload);
  };
  
  export const updateUsr = function* () {
    yield takeEvery(UPDATE_USER, updateUserRequest);
  };
  
  


export default function* rootSaga() {
  yield all([
    
    fork(fetchAllUsr),
    fork(searchAllUsr),
    fork(canclSubs),
    fork(activateTriallSubb),
    fork(reactivateSubs),
    fork(loneRangerMnthlySubs),
    fork(loanRangerQtr),
    fork(loanRangerBiannl),
    fork(loanRangerYrl),
    fork(extendUsrSubs),
    fork(deleteUsr),
    fork(fetchUsrById),
    fork(updateUsr)
  ]);
}
