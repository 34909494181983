import { 
  
  
    SEND_NOTIFICATION,
    SEND_NOTIFICATION_SUCCESS,
    RESTORE_SEND_NOTIFICATION_INITIAL
   
   
   } from "../ActionTypes";



export const sendNotification = (payload) => ({  
    type: SEND_NOTIFICATION,
    payload
    
});

export const sendNotificationSuccess = payload => ({
    type: SEND_NOTIFICATION_SUCCESS,
    payload
});

export const restoreSendNotificationInitial = () => ({
    type: RESTORE_SEND_NOTIFICATION_INITIAL,
  });