import api from "../Service";
import { toastr } from "react-redux-toastr";

const baseController = "api/admin/";

export const getBlogs = async () => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard/blog/all`);
      if (typeof response !== "undefined") {
        if (response.status === 200) {
          return response.data.data;
        }
      } else {
        toastr.error("An Error occured", "Could not retrieve blogs");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};


export const getBlogById = async (id) => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard/blog/find/${id}`);
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          return response.data.data;
        }
      } else {
        toastr.error("An Error occured", "Could not get blog ");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};


export const addBlog = async (body) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.post(`${baseController}dashboard/blog/create`, body, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            toastr.success(`${response.data.message}`, "Success");
            return response.data.status;
          } else {
            toastr.error(`${response.data.message}`, "Opps! We couldnt create blog");
            return response.data.status;
          }
        } else {
          toastr.error("An Error Occured", "Please try again");
        }
      } catch (ex) {
        toastr.error("An Error Occured", "Please try again");
            return ex.response.data.message
      }
    }
  };


  export const editBlog = async (body) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.post(`${baseController}dashboard/blog/edit/${body.id}`, body);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            toastr.success(`${response.data.message}`, "Success");
            return response.data.status;
          } else {
            toastr.error("Something went wrong", "Please try again");
            return response.data.status;
          }
        } else {
          toastr.error("An Error Occured", "Please try again");
        }
      } catch (ex) {
        toastr.error("An Error Occured", "Please try again");
            return ex.response.data.message
      }
    }
  };


  export const createBlogUrl = async (body) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.post(`${baseController}dashboard/blog/update/url/${body.id}`, body);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            toastr.success(`${response.data.message}`, "Success");
            return response.data.status;
          } else {
            toastr.error("Something went wrong", "Please try again");
            return response.data.status;
          }
        } else {
          toastr.error("An Error Occured", "Please try again");
        }
      } catch (ex) {
        toastr.error("An Error Occured", "Please try again");
            return ex.response.data.message
      }
    }
  };

  export const deleteBlog = async (id) => {
    if (navigator.onLine === false) {
      toastr.error("No Internet Connection", "Please try again");
    } else {
      try {
        const response = await api.get(`${baseController}dashboard/blog/delete/${id}`);
        if (typeof response !== "undefined") {
          if (response.status === 200 && response.data.status === true) {
            toastr.success("Blog Deleted Successfully", "");
            return response.data.status;
          }
        } else {
          toastr.error("An Error occured", "Could not delete blog");
        }
      } catch (ex) {
        toastr.error("An Error occurred", "Please try again");
      }
    }
  };