import { 
  
    FETCH_ALL_USERS,
    FETCH_ALL_USERS_SUCCESS,
    EMPTY_USERS,
    CANCEL_SUBSCRIPTION,
    CANCEL_SUBSCRIPTION_SUCCESS,
    RESTORE_CANCEL_SUBSCRIPTION_INITIAL,
    TRIAL_SUBSCRIPTION,
    TRIAL_SUBSCRIPTION_SUCCESS,
    RESTORE_TRIAL_SUBSCRIPTION_INITIAL,
    LONE_RANGER_MONTHLY_SUBSCRIPTION,
    LONE_RANGER_MONTHLY_SUBSCRIPTION_SUCCESS,
    RESTORE_LONE_RANGER_MONTHLY_SUBSCRIPTION_INITIAL,
    LONE_RANGER_QUARTERLY_SUBSCRIPTION,
    LONE_RANGER_QUARTERLY_SUBSCRIPTION_SUCCESS,
    RESTORE_LONE_RANGER_QUARTERLY_SUBSCRIPTION_INITIAL,
    LONE_RANGER_BIANNUAL_SUBSCRIPTION,
    LONE_RANGER_BIANNUAL_SUBSCRIPTION_SUCCESS,
    RESTORE_LONE_RANGER_BIANNUAL_SUBSCRIPTION_INITIAL,

    LONE_RANGER_YEARLY_SUBSCRIPTION,
    LONE_RANGER_YEARLY_SUBSCRIPTION_SUCCESS,
    RESTORE_LONE_RANGER_YEARLY_BIANNUAL_SUBSCRIPTION_INITIAL,

    EXTEND_USER_SUBSCRIPTION,
    EXTEND_USER_SUBSCRIPTION_SUCCESS,
    RESTORE_EXTEND_USER_SUBSCRIPTION_INITIAL,

    REACTIVATE_SUBSCRIPTION,
    REACTIVATE_SUBSCRIPTION_SUCCESS,
    RESTORE_REACTIVATE_SUBSCRIPTION_INITIAL,

    SEARCH_USERS,
    SEARCH_USERS_SUCCESS,

    DELETE_USERS,
    DELETE_USERS_SUCCESS,
    RESTORE_DELETE_USER_INITIAL,

    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    RESTORE_UPDATE_USER_INITIAL,

    FETCH_USER_BY_ID,
    FETCH_USER_BY_ID_SUCCESS
  
  } from "../ActionTypes";


  export const fetchUsers = (id) => ({
    type: FETCH_ALL_USERS,
    payload: id
  });
  
  export const fetchUsersSuccess = (payload) => ({
    type: FETCH_ALL_USERS_SUCCESS,
    payload,
  });


  export const emptyUsers = () => ({
    type: EMPTY_USERS,
  });



  export const searchUsers = ({email}) => ({
    type: SEARCH_USERS,
    payload: {
        email
    }
  });
  
  export const searchUsersSuccess = (payload) => ({
    type: SEARCH_USERS_SUCCESS,
    payload,
  });




  export const cancelSubscription = (id) => ({
    type: CANCEL_SUBSCRIPTION,
    payload: id
  });
  
  export const cancelSubscriptionSuccess = (payload) => ({
    type: CANCEL_SUBSCRIPTION_SUCCESS,
    payload,
  });


  export const restoreCancelSubscriptionInitial = () => ({
    type: RESTORE_CANCEL_SUBSCRIPTION_INITIAL,
  });



  export const reactivateSubscription = (id) => ({
    type: REACTIVATE_SUBSCRIPTION,
    payload: id
  });
  
  export const reactivateSubscriptionSuccess = (payload) => ({
    type: REACTIVATE_SUBSCRIPTION_SUCCESS,
    payload,
  });


  export const restoreReactivateSubscriptionInitial = () => ({
    type: RESTORE_REACTIVATE_SUBSCRIPTION_INITIAL,
  });




  export const activateTrialSubscription = (id) => ({
    type: TRIAL_SUBSCRIPTION,
    payload: id
  });
  
  export const activateTrialSubscriptionSuccess = (payload) => ({
    type: TRIAL_SUBSCRIPTION_SUCCESS,
    payload,
  });


  export const restoreActivateTrialSubscriptionInitial = () => ({
    type: RESTORE_TRIAL_SUBSCRIPTION_INITIAL,
  });


  export const activateLoanRangerMonthlySubscription = (id) => ({
    type: LONE_RANGER_MONTHLY_SUBSCRIPTION,
    payload: id
  });
  
  export const activateLoanRangerMonthlySubscriptionSuccess = (payload) => ({
    type: LONE_RANGER_MONTHLY_SUBSCRIPTION_SUCCESS,
    payload,
  });


  export const restoreLoanRangerMonthlySubscriptionInitial = () => ({
    type: RESTORE_LONE_RANGER_MONTHLY_SUBSCRIPTION_INITIAL,
  });





  export const activateLoanRangerQuartlySubscription = (id) => ({
    type: LONE_RANGER_QUARTERLY_SUBSCRIPTION,
    payload: id
  });
  
  export const activateLoanRangerQuartlySubscriptionSuccess = (payload) => ({
    type: LONE_RANGER_QUARTERLY_SUBSCRIPTION_SUCCESS,
    payload,
  });


  export const restoreLoanRangerQuartlySubscriptionInitial = () => ({
    type: RESTORE_LONE_RANGER_QUARTERLY_SUBSCRIPTION_INITIAL,
  });





  export const activateLoanRangerBiannualSubscription = (id) => ({
    type: LONE_RANGER_BIANNUAL_SUBSCRIPTION,
    payload: id
  });
  
  export const activateLoanRangerBiannualSubscriptionSuccess = (payload) => ({
    type: LONE_RANGER_BIANNUAL_SUBSCRIPTION_SUCCESS,
    payload,
  });


  export const restoreLoanRangerBiannualSubscriptionInitial = () => ({
    type: RESTORE_LONE_RANGER_BIANNUAL_SUBSCRIPTION_INITIAL,
  });



  
  export const activateLoanRangerYearlySubscription = (id) => ({
    type: LONE_RANGER_YEARLY_SUBSCRIPTION,
    payload: id
  });
  
  export const activateLoanRangerYearlySubscriptionSuccess = (payload) => ({
    type: LONE_RANGER_YEARLY_SUBSCRIPTION_SUCCESS,
    payload,
  });


  export const restoreLoanRangerYearlySubscriptionInitial = () => ({
    type: RESTORE_LONE_RANGER_YEARLY_BIANNUAL_SUBSCRIPTION_INITIAL,
  });



  export const extendUserSubscription = ({ date, id }) => ({
    type: EXTEND_USER_SUBSCRIPTION,
    payload: {
        date,
        id
    },
  });
  
  export const extendUserSubscriptionSuccess = (payload) => ({
    type: EXTEND_USER_SUBSCRIPTION_SUCCESS,
    payload,
  });
  
  export const restoreExtendUserSubscriptionInitial = () => ({
    type: RESTORE_EXTEND_USER_SUBSCRIPTION_INITIAL,
  });



  export const deleteUsers = (email) => ({
    type: DELETE_USERS,
    payload: email
  });
  
  export const deleteUsersSuccess = (payload) => ({
    type: DELETE_USERS_SUCCESS,
    payload,
  });


  export const restoreDeleteUsersInitial = () => ({
    type: RESTORE_DELETE_USER_INITIAL,
  });


  export const updateUser = ({full_name, phone, gender, id}) => ({
    type: UPDATE_USER,
    payload: {
      full_name, 
      phone, 
      gender,
      id
    }
  });
  
  export const updateUserSuccess = (payload) => ({
    type: UPDATE_USER_SUCCESS,
    payload,
  });

  export const restoreUpdateUserInitial = () => ({
    type: RESTORE_UPDATE_USER_INITIAL,
  });


  export const fetchUserById = (id) => ({
    type: FETCH_USER_BY_ID,
    payload: id
  });
  
  export const fetchUserByIdSuccess = (payload) => ({
    type: FETCH_USER_BY_ID_SUCCESS,
    payload,
  });